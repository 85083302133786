<template>
    <div class="flex-row">
        <div class="position-relative flex justify-center bg-white full-width full-height mr-20"
             style="border-radius: 20px; width: 70%"
        >
            <div class="full-width">
                <div class="flex-row justify-space-between pa-20">
                    <p class="size-px-16 font-weight-500">{{ live_info.cartl_chttg_room_name }}</p>
                    <button @click="$emit('onBack')">
                        <img src="@/assets/image/btn_exit.svg" alt="exit">
                    </button>
                </div>
                <div class="bg-live position-relative controller_hover fullscreen-wrapper"
                     :style="live_info.cartl_chttg_img_url
                ? 'background-image: url(' + live_info.cartl_chttg_img_url + ');'
                : ''"
                >
                    <div class="flex justify-center" style="overflow: hidden; align-items: center;"
                         :style="screen_mode ? 'height: 100%' : 'height: 372px'"
                    >
                        <video
                            ref="videoRef"
                            id="video"
                            src=""
                            muted autoplay

                            style="width: 100% !important; height: 100% !important; object-fit: cover;"
                            class="box-screen"

                            @mousemove="showLayout"
                            @error="onVideoError"
                        >
                        </video>
                        <div class="position-absolute full-width full-height" style="top:0; left: 0;"
                             v-if="!is_streaming"
                             @mousemove="showLayout">
                            <div class="image_null">
                                <img src="@/assets/image/ani_icon_preparing.gif" alt="image_null">
                                <p class="size-px-14 color-white font-weight-400">준비중 입니다.</p>
                            </div>
                        </div>
                    </div>
                    <transition name="fade">
                        <div class="controller" v-if="show_layout">
                            <div class=" mr-10 position-absolute" v-show="screen_mode"
                                 style="bottom: 14px; left: 14px; display: inline-block; background: rgba(0, 0, 0, 0.50); padding: 5px 10px; border-radius: 16px;">
                                    <span
                                        class="color-white size-px-14 mr-5 ml-5"
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6"
                                         fill="none">
                                    <circle cx="3" cy="3" r="3" fill="#DC505C"/>
                                    </svg>
                                    <img src="@/assets/image/icon_user.svg" alt="user">
                                        {{ live_info.participation_member_count }}
                                    </span>
                            </div>
                            <div class="size_controller">
                                <button class="color-white" v-show="!screen_mode" @click="setFullscreen">
                                    <font-awesome-icon :icon="['fas', 'expand']" style="width: 35px;" />
<!--                                    <img src="@/assets/image/full_screen.svg" alt="full_screen">-->
                                </button>
                                <div v-show="screen_mode">
                                    <button class="color-white mt-auto" @click="setFullscreen">
                                        <font-awesome-icon :icon="['fas', 'compress']" style="width: 35px;" />
<!--                                        <img src="@/assets/image/default_screen.svg" alt="default_screen">-->
                                    </button>
                                </div>
                            </div>
                            <div>
                                <button
                                    v-if="screen_mode"
                                    @click="show_chat = !show_chat"
                                    class="position-absolute"
                                    style="width: 40px; height: 40px; background-color: rgba(255, 255, 255, 0.5); border-radius: 100%; top: 14px; right: 14px;"
                                >
                                    <img :src="require('@/assets/image/btn_chat_on.svg')" class="width-100" v-if="!show_chat">
                                    <img :src="require('@/assets/image/btn_chat_off.svg')" class="width-100" v-else>
                                </button>
                            </div>
                        </div>
                    </transition>
                    <div class="position-absolute fullscreen_chat" v-if="screen_mode">
                        <LiveChat
                            v-if="show_chat"
                            :user="user"
                            :live_info="live_info"
                            :live_in="live_in"
                            :items_list="items_list"

                            class="full-width"
                            style="border-radius: 20px; background-color: rgba(255,255,255,0.8)"
                            @sendMessage="sendMessage"
                        />
                    </div>
                </div>
                <div>
                    <div class="size-px-14 flex-row justify-space-between items-center pa-20">
                        <div>
                            <img :src="live_info.profle_nft_card_img_url"
                                 @error="$bus.$emit('onErrorProfileImage', $event)"
                                 style="width: 32px; height: 32px; border-radius: 100%; object-fit: cover; margin-right: 5px; font-size: 16px"/>
                            <span class="mt-auto ml-3">{{ live_info.chttg_room_progress_member_nickname }}</span>
                        </div>
                        <div class="flex mr-5" style="border: 1px solid #DADADA; border-radius: 20px; padding: 3px 10px" @click="$emit('openUserList')">
                            <img src="@/assets/image/icon_user_black.svg" alt="user">
                            <span>
                            {{ live_info.participation_member_count }}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LiveChat
            :user="user"
            :live_info="live_info"
            :live_in="live_in"
            :items_list="items_list"

            style="width: 30%"
            @sendMessage="sendMessage"
        />
    </div>
</template>

<script>
import {api as screenApi} from "vue-fullscreen";
import LiveChat from "@/view/LiveChat/LiveChat.vue";

export default {
    name: 'LiveStreaming'
    ,
    components: {LiveChat},
    props: ['user', 'cartel_info', 'live_in', 'live_info', 'is_on_stream', 'is_on_stage', 'is_out', 'fullscreen', 'items_list']
    , data: function () {
        return {
            is_streaming: false
            , is_video: true
            , playerOptions: {
                // videojs options
                autoplay: true,
                muted: true,
                language: 'kr',
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                poster: this.live_info.cartl_chttg_img_url
            }
            , screen_mode: false
            , show_layout: false
            , show_chat: true
        }
    }
    , methods: {
        sendMessage(msg) {
            this.$emit('sendMessage', msg)
        }
        , showLayout() {
            if (!this.show_layout) {
                this.show_layout = true

                setTimeout(() => {
                    this.show_layout = false
                }, 2000)
            }
        }
        , async setFullscreen() {
            await screenApi.toggle(this.$el.querySelector('.fullscreen-wrapper'), {
                teleport: this.teleport,
                callback: (isFullscreen) => {
                    this.screen_mode = isFullscreen
                },
            })
            this.$emit('setScreen', screenApi.isFullscreen)
        }
        , onVideo: function () {
            if (this.is_on_stream) {
                setTimeout(() => {
                    let videoObj = this.$refs.videoRef;
                    videoObj.setAttribute('playsinline', this.playsinline)
                    videoObj.setAttribute('webkit-playsinline', this.playsinline)
                    videoObj.setAttribute('x5-playsinline', this.playsinline)
                    videoObj.setAttribute('x5-video-player-type', 'h5')
                    videoObj.setAttribute('x5-video-player-fullscreen', false)

                    console.log(this.live_in)
                    let video = document.getElementById('video');
                    let videoSrc = this.live_in.srurl + '?token=' + this.live_in.srt;
                    console.log('videoSrc', videoSrc)
                    if (video) {
                        if (video.canPlayType('application/vnd.apple.mpegurl')) {
                            video.src = videoSrc;
                        } else if (window.Hls.isSupported()) {
                            let hls = new window.Hls();
                            hls.on(window.Hls.Events.MANIFEST_PARSED, () => {
                                video.muted = true;
                                video.play();
                            });
                            hls.on(window.Hls.Events.ERROR, (event, data) => {
                                console.log(event, data, 'hls error')
                                if (data.type === 'networkError' && data.details === 'manifestLoadError') {
                                    console.log('network error')
                                    setTimeout(() => {
                                        hls.loadSource(videoSrc);
                                        hls.attachMedia(video);
                                    }, 1000)
                                }
                            });
                            hls.loadSource(videoSrc);
                            hls.attachMedia(video);
                        }
                    }
                    this.is_streaming = true
                    console.log('onVideo')
                    console.log(videoSrc)
                }, 1000)
                this.setCurrentTime()
            }
        }
        , setCurrentTime() {
            const video = document.getElementById('video')
            video.addEventListener('timeupdate', () => {
                if (Number(video.currentTime) > 0) {
                    if (video.buffered.end(0) - Number(video.currentTime) > 4) {
                        console.log(video.currentTime, '---------------', video.buffered.end(0))
                        video.currentTime = video.buffered.end(0)
                    }
                }
            })
        }
        , offVideo: function () {
            console.log('off')
            this.is_streaming = false
            this.is_video = false
            let video = document.getElementById('video');
            if (video) {
                video.src = '';
            }
            setTimeout(() => {
                this.is_video = true
            }, 100)
        }
        , onVideoError: function (evt, err) {
            console.log(evt, err)
            console.log('onVideoError')
            if (this.is_streaming) {
                setTimeout(() => {
                    this.onVideo()
                }, 100)
            }
        }
    }
    , created() {
        this.onVideo()
    }
    , watch: {
        fullscreen: {
            immediate: true
            , handler: function () {
                this.screen_mode = this.fullscreen
            }
        }
        , is_on_stage: {
            handler: function (call) {
                console.log('is_on_stage', call)

            }
        }
        , is_on_stream: {
            immediate: true
            , deep: true
            , handler: function (call) {
                console.log('is_on_stream ing', call)
                if (call) {
                    this.onVideo()
                } else {
                    this.offVideo()
                }
            }
        }
    }
}
</script>

<style scoped>
.fullscreen_chat {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-top: 80px;
    bottom: 12%;
    right: 40px;
    width: 380px;
    height: 85%;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0s ease;
}

.fade-enter-from,
.fade-leave-to {
    margin-top: 10px;
    opacity: 0;
    transition: 0.3s;
}

.bg-live {
    background-position: 50%;
    background-size: cover;
    background-image: url(../../assets/image/@cartel_cover_basic.png);
}

.image_null {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.25);
}

.image_null img {
    height: 80px;
    width: 80px;
}

.controller {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    top: 0;
    background: rgba(0, 0, 0, 0.35);
}

.size_controller {
    position: absolute;
    z-index: 5;
    padding: 1rem;
    right: 0;
    bottom: 0;
}

.wrap-screen {
    overflow: hidden;
    position: relative;
    height: 240px;
}

.wrap-screen-horizontal.wrap-screen {

}

.wrap-full-screen.wrap-screen {
    height: 100%;
    max-height: 100%;
}


.box-screen {
    object-fit: cover;
    max-height: 100%;
}

.wrap-full-screen .box-screen {
    object-fit: contain;
    height: 100%;
}

.box-full-screen.box-screen {
    width: 100%;
}

.box-screen-horizontal.box-screen {

}
</style>