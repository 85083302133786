<template>
    <div>
        <PopupLayer>
            <template
                v-slot:body
            >
                <div
                    class="flex-column radius-20 text-left  radius-20 overflow-hidden"
                    style="min-width: 320px; max-width: 480px; margin: 0 auto; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
                >
                    <div class="bg-mafia-blue flex-row justify-space-between items-center pa-13-30 pr-20">
                        <div class="color-white flex-row">
                            <h4 class="color-white mr-10">참여 멤버</h4>
                            <span class="mt-5">{{  item_cartel.cartl_name }}</span>
                        </div>
                        <img class="cursor-pointer"
                             @click="$emit('closeModal')"
                             :src="require('@/assets/image/web/notify_cartel/ico_close.svg')"
                        />
                    </div>

                    <div class="bg-white">
                        <div class="pa-8-20 flex-row search_border">
                            <div class="flex-1">
                                <input
                                    type="text" placeholder="멤버 검색"
                                    maxlength="20"
                                    v-model="keyword"
                                    style="width: 100%"
                                    v-on:keyup.enter="searchUser"
                                >
                            </div>

                            <button @click="keyword = ''">
                                <v-icon
                                    class="color-dadada"
                                >mdi-close-circle
                                </v-icon>
                            </button>
                            <button @click="searchUser">
                                <v-icon
                                    class="ml-10 color-gray"
                                >mdi-magnify
                                </v-icon>
                            </button>
                        </div>
                        <div class="mt-20 ml-30 mr-30 flex-row justify-space-between">
                          <h5>시청 중인 멤버</h5>
                          <h5>{{ participant_list.length }}명</h5>
                        </div>
                        <div class="pa-10-20">
                            <div class="content_wrap">
                                <ul class="mr-20 ml-20">
                                    <li
                                        v-for="(item, index) in participant_list"
                                        :key="'item_' + index"
                                    >
                                        <div class="picture">

                                            <img
                                                v-if="item.profle_nft_card_img_url"
                                                :src="$request.upload_url(item.profle_nft_card_img_url)"
                                                referrerpolicy="no-referrer"
                                            >
                                            <img
                                                v-else
                                                :src="require('@/assets/image/@profile_inner.png')" alt="추천카르텔"
                                                referrerpolicy="no-referrer"
                                            />
                                        </div>
                                        <div class="text_area">
                                            <strong class="font-weight-500">{{ item.nickname }}</strong>
                                            <p class="textzone textzone color-888 mt-5">
                                                {{ item.cartl_member_grade_name }}
                                            </p>
                                        </div>
                                        <div class="ct_invite_btn"
                                             v-if="item.cartl_member_div_code !== 'CA02500001' && live_info.chttg_room_progress_member_number == user.member_number"
                                        >
                                            <button
                                                class="btn-inline radius-30 pa-10-20 size-px-12 font-weight-500"
                                                @click="selectUser(item)"
                                                :disabled="false"
                                            >강퇴
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                                <div
                                    v-if="participant_list.length <= 0"
                                    class="pa-10 flex-column justify-center items-center"
                                    style="transform: translateY(50%)"
                                >
                                    <div><img :src="require('@/assets/image/list_none.png')" alt="이력없음"
                                              style="width: 120px"></div>
                                    <div class="mt-20">시청 중인 멤버가 없습니다.</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </template>
        </PopupLayer>

        <!--      강퇴 ( 개설자 )     -->
        <PopupConfirm
            v-if="is_open_popup && live_info.chttg_room_progress_member_number == this.user.member_number"
            @cancel="closePopup()"
            @click="compulsory"
        >
            <template v-slot:title>{{ $language.live.live_compulsory_exit_title }}</template>
            <template
                v-slot:main-txt
            >
                <div :inner-html.prop="$language.live.live_compulsory_exit | nl2br"></div>
            </template>
            <template
                v-slot:sub-txt
            >
                <p class="mt-10">{{ selected_user.nickname }}</p>
            </template>
            <template
                v-slot:name-cancel
            >{{ $language.common.system_notice_btn02 }}
            </template>
            <template
                v-slot:name-confirm
            >{{ $language.common.compulsory_exit }}
            </template>

        </PopupConfirm>

    </div>

</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
import PopupConfirm from "@/view/Layout/PopupConfirm";

export default {
    name: "LiveUserList",
    components: {PopupLayer, PopupConfirm},
    props: ['participant_list', 'live_info', 'user', 'item_cartel', 'is_compulsory']
    , data() {
        return {
            program: {
                title: '라이브 채팅 참여 멤버'
            }
            , keyword: ''
            , item_search: ''
            , is_open_popup: false
            , selected_user: []
        }
    }
    , methods: {
        compulsory: async function () {
            try {
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_cartel_live_compulsory_exit
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                        , cartl_chttg_room_number: this.$route.params.live_idx
                        , exit_member_number: this.selected_user.member_number
                    }
                    , type: true
                })

                if (result.success) {
                    console.log(result)
                    this.closePopup()
                } else {
                    throw result.message
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.$bus.$emit('on', false)
            }
        }
        , selectUser: function (item) {
            this.selected_user = item
            this.is_open_popup = true
            console.log(this.selected_user)
            console.log(this.item_cartel)
        }
        , closePopup() {
            this.is_open_popup = false
            this.selected_user = []
        }
        ,searchUser() {
            console.log(this.keyword)
            this.$emit('searchUser',this.keyword)
        }
    }
}
</script>

<style scoped>
li {
    display: flex;
    column-gap: 10px;
    margin-top: 15px;
    align-items: center;
    /* height: 50px; */
}

.search_border {
  border: 1px solid #DADADA;
  border-radius: 30px;
  margin: 30px 30px 10px 30px;
}

.text_area {
    width: 320px;
}

img {
    /* border-radius: 50%; */
}

.btn-inline {
    background-color: white;
    color: var(--blue02);
    border: 1px solid var(--blue02); /* Green */
    transition: 0.2s all;
}

.btn-inline:hover {
    background-color: var(--blue02) !important;
    color: white;
}

.content_wrap {
    height: 350px;
    overflow-y: auto;
}

.content_wrap::-webkit-scrollbar {
    width: 4px;
}

.content_wrap::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 10px;
}

.content_wrap::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: 10px;
}

.picture img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50px;
}
</style>