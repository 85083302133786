<template>
    <div
        class="position-fixed-full radius-20 z-index-layer"
        v-if="show"
    >
        <div class="bg-layer"></div>
        <div
            class="position-relative viewer pa-20 flex-column justify-center full-width full-height items-center"
        >
            <div class="width-500 bg-white" style="border-radius: 20px; height: 600px">
                <div class="top top_mafi"><!--trans-->
                    <div class="util shadow ">
                        <p class="color-white size-px-16">{{ $language.live.live_request_modify_title }}</p>
                    </div>
                    <div class="save" style="right: 20px">
                        <button
                            @click="closeModal"
                            class="btn_save"
                        >
                            <v-icon class="color-white">mdi-close</v-icon>
                        </button>
                    </div>
                </div>
                <div class="position-relative" style="height: calc(100% - 50px);">
                    <div class="section_wrap flex-column justify-space-between">
                        <!-- 커버등록 -->
                        <div class="cartel_cover">
                            <label class="box pa-5-10 radius-10 size-px-12 bg-white-half"
                                   style="right: 10px; top: 10px; z-index: 9; position: absolute">
                                <v-icon small class="">mdi-camera</v-icon>
                                썸네일 사진 등록
                                <InputFileNew class="fileform_hidden" accept="image/*"
                                              @change="setFile($event, 'mobile')"
                                              ref="file" file_upload_code_info="CM00700021"/>
                            </label>
                            <div
                                v-if="item_live.cartl_chttg_img_url"
                                class="cover_background"
                            >
                                <img
                                    :src="item_live.cartl_chttg_img_url"
                                    style="width: 100%"
                                />
                            </div>

                            <div
                                v-else
                                class="text-center size-px-12 text-shadow flex-column justify-center full-height color-white"
                            >썸네일을 등록하세요 <br/> <span class="color-gray">* 가로 750px 세로 500px 이상</span></div>

                        </div>
                    </div>

                    <div class="d-flex justify-center">
                        <div class="subindex_mainbox">

                            <!-- 카르텔이름 -->
                            <div class="input_area">
                                <div class="bright_input">
                                    <label for="cartelName" class="font-weight-500 ml-10 text-left"
                                           style="color:#333333; font-size: 16px; margin-bottom: 14px">
                                        {{ $language.live.live_name }}
                                    </label>
                                    <div class="input_utill">
                                        <input
                                            v-model="item_live.cartl_chttg_room_name"
                                            type="text" id="cartelName" :placeholder="$language.live.live_name_required"
                                            maxlength="40"
                                        >
                                        <div class="input_utill_area">
                                            <div class="btn_del">
                                                <!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
                                                <button
                                                    v-if="item_live.cartl_chttg_room_name"
                                                    @click="item_live.cartl_chttg_room_name = ''"
                                                    class="active"
                                                ><span class="hide">{{ $language.live.live_name_required }}</span>
                                                </button>
                                            </div>
                                            <!-- TODO : 텍스트 수 카운트 필요 -->
                                            <span class="input_text_count" v-if="item_live.cartl_chttg_room_name">
                                            {{ item_live.cartl_chttg_room_name.length }}/40
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- //카르텔이름 -->
                        </div>

                        <div
                            class="mt-auto pa-20 full-width flex justify-center" style="position: absolute; bottom: 10px"
                        >
                            <button
                                v-show="!is_on_stream"
                                class="btn_l2 btn_fill_red mr-20" @click="stopChat()"
                            >{{ $language.live.live_exit }}
                            </button>
                            <button
                                @click="modifyCartelLive"
                                class="btn_l2 btn_fill_blue"
                                :disabled="is_live_request"
                            >{{ $language.live.live_request_modify }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InputFileNew from "@/components/InputFileNew";

export default {
    name: 'CartelLiveRequestModify'
    , components: {InputFileNew}
    , props: ['user', 'live_info', 'is_open_modify', 'is_on_stream']
    , data: function () {
        return {
            program: {
                name: 'CartelLiveRequestModify'
                , title: this.$language.cartel.cartel_live_request_modify
                , type: 'mypage'
                , not_footer: true
                , not_header: true
                , is_next: true
                , callBack: {
                    name: 'cartel'
                    , step: 1
                }
            }
            , show: false
            , item_live: {
                cartl_chttg_room_name: ''
                , stremg_fg: true
                , cartl_number: ''
                , cartl_chttg_img_url: ''
                , cartl_chttg_room_div_code: 'CA02900001'
            }
        }
    }
    , computed: {
        is_live_request: function () {
            let t = true
            if (this.item_live.cartl_chttg_room_name) {
                t = false
            }
            return t
        }
    }
    , methods: {
        setFile: function (e) {
            this.$set(this.item_live, 'cartl_chttg_img_url', e)
        }
        , postFile: async function () {
            return true
        }
        , modifyCartelLive: async function () {
            console.log('modifyCartelLive', this.item_live)

            if (await this.postFile()) {
                try {
                    this.$bus.$emit('on', true)
                    const result = await this.$Request({
                        method: 'post'
                        , url: this.$api_url.api_path.post_cartel_live_modify
                        , data: {
                            member_number: this.user.member_number
                            , cartl_number: this.item_live.cartl_number
                            , cartl_chttg_room_number: this.$route.params.live_idx
                            , cartl_chttg_room_name: this.item_live.cartl_chttg_room_name
                            , cartl_chttg_room_desctn: this.item_live.cartl_chttg_room_desctn
                            , cartl_chttg_room_state_code: this.live_info.cartl_chttg_room_state_code
                            , stremg_fg: this.live_info.stremg_fg
                            , cartl_chttg_img_url: this.item_live.cartl_chttg_img_url
                        }
                        , name: 'modifyCartelLive'
                        , type: true
                    })

                    if (result.success) {
                        console.log()
                    } else {
                        throw result.message
                    }

                } catch (e) {
                    console.log(e)
                    this.$bus.$emit('notify', {type: 'error', message: e})
                } finally {
                    this.$bus.$emit('on', false)
                }
            }
        }
        , closeModal: function () {
            // this.show = false
            this.$emit('closeModify')
        }
        // Stop the chat
        , stopChat: async function () {
            try {
                console.log('stop chat')
                // await this.client.stopChat();
                this.$emit('offChat')
            } catch (err) {
                this.setError(err.toString());
            }
        }
    }
    , watch: {
        is_open_modify: {
            immediate: true
            , handler: function (call) {
                console.log('create live', call)
                if(call) {
                    this.item_live = {
                        cartl_chttg_room_name: this.live_info.cartl_chttg_room_name
                            , stremg_fg: true
                            , cartl_number: this.$route.params.idx
                            , cartl_chttg_img_url: this.live_info.cartl_chttg_img_url
                            , cartl_chttg_room_div_code: 'CA02900001'
                    }
                    this.show = true
                } else {
                    this.show = false
                }
            }
        }
    }
}
</script>

<style scoped>
.cover_background {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    border-radius: 20px;
}

.cover_background::before {
    z-index: 0;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.cover_background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.bright_input {
    display: flex;
    flex-direction: column;
}

.btn_del button.active {
    width: 20px;
    height: 20px;
    background: url(../../assets/image/btn_del.png) no-repeat center center / 20px 20px;
}

.input_utill .input_text_count {
    color: var(--gray01);
    font-size: 14px;
    line-height: 14px;
    padding-left: 10px;
}

.input_utill_area {
    position: absolute;
    right: 20px;
    top: 0;
    height: 50px;
    display: flex;
    align-items: center;
}

.btn_save {
    font-size: 18px;
    line-height: 45px;
    font-weight: 700;
    color: #fff;
    background-color: transparent;
}

.cartel_cover {
    border-radius: 20px;
    position: relative;
    height: 240px;
    background-position: 50%;
    background-size: cover;
    background-image: url(../../assets/image/@cartel_cover_basic.png);
}

.section_wrap {
    position: relative;
    padding: 20px;
}

.section_wrap::after {
    content: '';
    display: block;
    padding-bottom: 0;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 50px;
    background-color: #171C61;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.subindex_mainbox {
    width: 100%;
    padding: 0 20px;
}

.btn_l2 {
//width: 100%; height: 50px; line-height: 48px; padding: 0 30px; border-radius: 50px; box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
}

.btn_fill_red {
    background-color: var(--red);
    border-color: var(--red);
    color: #fff;
}

.box-profile-outline {
    position: absolute;
    left: calc(50% - 45px);
    bottom: -45px;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2C2C2C;
    width: 95px;
    height: 95px;
    border-radius: 100%;
    overflow: hidden;
}

.box-profile-inline {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 95px;
    font-size: 12px;
    border-radius: 100%;
    color: var(--white);
}

.box-profile-outline img {
    object-fit: cover;
}
</style>