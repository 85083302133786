<template>
    <div
        class="mt-30 text-left flex-row" style="height: 535px; padding: 0 50px"
    >
        <LiveSetting
            v-if="item_live.chttg_room_progress_member_number == this.user.member_number"
            :user="user"
            :live_info="item_live"
            :live_in="item_live_in"
            :is_out="is_out"
            :is_on_stage="is_on_stage"
            :is_on_stream="is_on_stream"
            :items_list="items_list"

            @openUserList="show_participant = true"
            @sendMessage="sendMessage"
            @setScreen="setScreen"
            @onStream="onStream"
            @offStream="offStream"
            @openModifyPopup="openModifyPopup"
            class="z-index-layer"
            style="width: 100%"
        />
        <LiveStreaming
            v-else-if="item_live.chttg_room_progress_member_number != this.user.member_number"
            :user="user"
            :live_info="item_live"
            :live_in="item_live_in"
            :is_out="is_out"
            :is_on_stage="is_on_stage"
            :is_on_stream="is_on_stream"
            :items_list="items_list"

            @openUserList="show_participant = true"
            @chatOff="is_chat_off = true"
            @onBack="onBack"
            @sendMessage="sendMessage"
            @setScreen="setScreen"

            class="z-index-layer-chat"
            style="width: 100%"
        ></LiveStreaming>
        <CartelEtc
            :user="user"
            :cartel_info="item_cartel"
            :bbs_info="{}"

            :only_nav="'only'"
        ></CartelEtc>
        <LiveRequestModify
            :user="user"
            :live_info="item_live"
            :is_on_stream="is_on_stream"
            :is_open_modify="is_open_modify"

            @offChat="is_open_chat_off = true"
            @closeModify="closeModify"
        >
        </LiveRequestModify>

        <!--      채팅방 나가기      -->
        <PopupConfirm
            v-if="is_on_out"
            @cancel="offBack"
            @click="goBack"
        >
            <template v-slot:title>{{ $language.live.live_out }}</template>
            <template
                v-slot:main-txt
            >
                <div :inner-html.prop="$language.live.live_out_txt | nl2br"></div>
            </template>
            <template
                v-slot:name-cancel
            >{{ $language.common.system_notice_btn02 }}
            </template>
            <template
                v-slot:name-confirm
            >{{ $language.live.live_out_btn }}
            </template>

        </PopupConfirm>

        <!--      채팅방 종료 ( 개설자 )     -->
        <PopupConfirm
            v-if="is_open_chat_off && item_live.chttg_room_progress_member_number == this.user.member_number"
            @cancel="is_open_chat_off = false"
            @click="offChat"
        >
            <template v-slot:title>{{ $language.live.live_off }}</template>
            <template
                v-slot:main-txt
            >
                <div :inner-html.prop="$language.live.live_chat_off_txt | nl2br"></div>
            </template>
            <template
                v-slot:name-cancel
            >{{ $language.common.system_notice_btn02 }}
            </template>
            <template
                v-slot:name-confirm
            >{{ $language.common.destroy }}
            </template>

        </PopupConfirm>

        <!--      채팅방 종료 ( 참여자 )     -->
        <PopupConfirm
            v-if="is_chat_off && item_live.chttg_room_progress_member_number != this.user.member_number"
            :not_cancel="true"
            @click="endChat"
        >
            <template v-slot:title>{{ $language.live.live_off }}</template>
            <template
                v-slot:main-txt
            >
                <div :inner-html.prop="$language.live.live_off_txt | nl2br"></div>
            </template>
            <template
                v-slot:name-confirm
            >{{ $language.common.ok }}
            </template>

        </PopupConfirm>
        <!--      강퇴 ( 참여자 )     -->
        <PopupConfirm
            v-if="is_compulsory && item_live.chttg_room_progress_member_number != this.user.member_number"
            :not_cancel="true"
            @click="$emit('goBack')"
        >
            <template v-slot:title>{{ $language.live.live_compulsory_exit_title_user }}</template>
            <template
                v-slot:main-txt
            >
                <div :inner-html.prop="$language.live.live_compulsory_exit_user | nl2br"></div>
            </template>
            <template
                v-slot:name-confirm
            >{{ $language.common.ok }}
            </template>

        </PopupConfirm>
        <LiveUserList
            v-if="show_participant"

            :user="user"
            :participant_list="participant_list"
            :live_info="item_live"
            :item_cartel="item_cartel"

            @searchUser="getParticipant"
            @closeModal="show_participant = false"
        />
    </div>
</template>

<script>
import LiveSetting from "@/view/LiveChat/LiveSetting.vue";
import LiveStreaming from "@/view/LiveChat/LiveStreaming.vue";
import CartelEtc from "@/view/Cartel/CartelEtc.vue";
import LiveRequestModify from "@/view/LiveChat/LiveRequestModify.vue";
import PopupConfirm from "@/view/Layout/PopupConfirm.vue";
import LiveUserList from "@/view/LiveChat/LiveUserList.vue";
export default {
    name: 'mafia074'
    , components: {
        LiveRequestModify,
        CartelEtc,
        LiveStreaming,
        LiveSetting,
        PopupConfirm,
        LiveUserList
    }
    , props: ['user']
    , data: function(){
        return {
            program: {
                name: 'LiveDetail'
                , title: '카르텔 채팅'
                , type: 'cartel_sub'
                , not_footer: true
                , not_header: true
                , from: 'mafia049'
                , bg_contents: 'bg-gray01'
                , cartel: ''
            }
            , item_cartel: {}
            , item_live: {}
            , item_live_in: {
                cartl_chttg_room_div_code: ''
                , chttg_conn_url: ''
                , chttg_participation_token: ''
                 ,chttg_room_user_div_code: ''
                , stremg_fg: 'Y'
                , sk: ''
                , spt: ''
                , srt: ''
                , srurl: ''
                , surl: ''
            }
            , items_comment: []
            , fullscreen: false
            , is_on_stage: false
            , is_on_stream: false
            , is_on_out: false
            , is_out: false
            , is_open_modify: false
            , is_chat_off: false
            , is_open_chat_off: false
            // socket
            , socket: ''
            , items: []
            , item_chat: ''
            , cmm: ''
            , sk: '' // 스트리밍 KEY
            , surl: '' // 스트리밍 엔드포인트
            , srurl: '' // 스트리밍 수신 URL
            , srt: '' // 스트리밍 수신 토큰
            , spt: '' // 스트리밍 참여 토큰
            , try: 0
            , ping: 0
            , ping_timeout: ''
            , is_shift: false
            , is_open_chat: true
            , participant_list: []
            , show_participant: false
            , is_compulsory: false
        }
    }
    , computed: {
        address: function () {
            let t = ''
            if (this.item_live.cartl_chttg_room_state_code == 'CA02600002' && this.item_live_in.cartl_chttg_room_div_code) {
                t = this.item_live_in.chttg_conn_url
            }

            return t
        }
        , ctoken: function () {

            let t = ''

            t = this.item_live_in.chttg_participation_token


            return t
        }
        , items_list: function () {
            let t = this.items
            return t
        }
    }
    , methods: {
        getCartel: async function(){
            try{
                this.$bus.$emit('on', true)
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.get_cartel_info
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                    }
                    , type: true
                })

                if(result.success){
                    this.item_cartel = result.data
                    this.program.cartel = result.data
                    this.$bus.$emit('onLoad', this.program)

                    switch (this.item_cartel.cartl_concern_sphere_code){
                        case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
                        case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
                        case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
                        case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
                    }
                }else{
                    throw result.message
                }
            }catch (e) {
                this.$log.console(e)
                this.$bus.$emit('notify', { type: 'error', message: e})
            }finally {
                this.$bus.$emit('on', false)
            }
        }
        , getData: async function () {
            try {
                //this.$bus.$emit('on', true)
                console.log('start get data')
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.get_cartel_live_info
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                        , cartl_chttg_room_number: this.$route.params.live_idx
                    }
                    , type: true
                })

                if (result.success) {
                    console.log('live broadcast get detail ---------------------------', result)
                    this.item_live = result.data
                    console.log(this.item_live.participation_member_count)
                    console.log(this.item_live)
                } else {
                    throw result.message
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.$bus.$emit('on', false)
            }
        }

        , postLiveIn: async function () {
            try {

                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_cartel_live_in
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                        , cartl_chttg_room_number: this.$route.params.live_idx
                    }
                    , type: true
                })

                if (result.success) {
                    this.item_live_in.cartl_chttg_room_div_code =  result.data.cartl_chttg_room_div_code
                    this.item_live_in.chttg_conn_url =  result.data.chttg_conn_url
                    this.item_live_in.chttg_participation_token =  result.data.chttg_participation_token
                    this.item_live_in.chttg_room_user_div_code =  result.data.chttg_room_user_div_code
                    this.item_live_in.stremg_fg =  result.data.stremg_fg
                    console.log('item_live_in get',this.item_live_in)
                } else {
                    throw result.message
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
                await this.goBack()
            } finally {
                this.$bus.$emit('on', false)
            }
        }
        , onStream: function ({sk, surl, srurl, srt, spt}) {
            console.log('onStream data set', { sk, surl, srurl, srt, spt})
            console.log('onStream data set2',this.item_live.chttg_room_progress_member_number != this.user.member_number)
            if (this.item_live.chttg_room_progress_member_number == this.user.member_number) {

                if (sk) {
                    this.$set(this.item_live_in, 'sk', sk)
                }
                if (surl) {
                    this.$set(this.item_live_in, 'surl', surl)
                }
                if (srurl) {
                    this.$set(this.item_live_in, 'srurl', srurl)
                }
                if (srt) {
                    this.$set(this.item_live_in, 'srt', srt)
                }
                if (spt) {
                    this.$set(this.item_live_in, 'spt', spt)
                }
                this.is_on_stream = true
            } else if (this.item_live.chttg_room_progress_member_number != this.user.member_number) {
                console.log('log', srurl, srt)
                if (srurl) {
                    this.$set(this.item_live_in, 'srurl', srurl)
                }
                if (srt) {
                    this.$set(this.item_live_in, 'srt', srt)
                }
                this.is_on_stream = true
            }

        }
        , onStage: function ({sk, surl, srurl, srt, spt}) {
            console.log('onStage data set', {sk, surl, srurl, srt, spt})
            if (this.item_live.chttg_room_progress_member_number == this.user.member_number) {

                if (sk) {
                    this.$set(this.item_live_in, 'sk', sk)
                }
                if (surl) {
                    this.$set(this.item_live_in, 'surl', surl)
                }
                if (srurl) {
                    this.$set(this.item_live_in, 'srurl', srurl)
                }
                if (srt) {
                    this.$set(this.item_live_in, 'srt', srt)
                }
                if (spt) {
                    this.$set(this.item_live_in, 'spt', spt)
                }
                this.is_on_stream = !!(srurl && srt);
                this.is_on_stage = true
            } else if (this.item_live.chttg_room_progress_member_number != this.user.member_number) {

                if (srurl) {
                    this.$set(this.item_live_in, 'srurl', srurl)
                }
                if (srt) {
                    this.$set(this.item_live_in, 'srt', srt)
                }
                this.is_on_stream = !!(srurl && srt);
                this.is_on_stage = true
            }

            this.getData()
        }
        , onSetting: function () {
            this.getData()
        }
        , offStream: function () {
            this.is_on_stream = false
            console.log(this.is_on_stream, 'stream off')
        }
        , offChat: async function () {
            try {
                if(!this.$route.params.idx){
                    return
                }
                console.log(this.$route.params.live_idx, 'off chat data')
                this.$bus.$emit('on', true)
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_cartel_live_expire
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                        , cartl_chttg_room_number: this.$route.params.live_idx
                    }
                    , type: true
                })

                if (result.success) {
                    console.log(result)
                    this.is_out = true
                    this.is_on_out = false
                    this.$router.push({ name:'mafia049', params: { idx: this.$route.params.idx } })
                } else {
                    throw result.message
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.$bus.$emit('on', false)
            }
        }
        , onBack: function () {
            this.is_out = true
            this.is_on_out = true
        }
        , offBack: function () {
            this.is_on_out = false
        }
        , endChat: function () {
            this.is_out = true
            this.is_on_out = false
            this.$emit('goBack')
        }
        , goBack: async function () {
            try {
                this.$bus.$emit('on', true)
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.post_cartel_liver_out
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                        , cartl_chttg_room_number: this.$route.params.live_idx
                    }
                    , type: true
                })

                if (result.success) {
                    console.log('go back out')
                    this.endChat()
                } else {
                    throw result.message
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.$bus.$emit('on', false)
            }
        }
        , openModifyPopup() {
            this.is_open_modify = true

        }
        , closeModify() {
            console.log('test close')
            this.is_open_modify = false
            this.getData()
        }
        , setScreen(screen){
            this.fullscreen = screen
        }
        // socket
        ,async connect() {

            this.disconnect()
            console.log('connect', this.socket)
            console.log('is out', this.is_out)
            if(!this.address || !this.ctoken){
                return
            }
            try {
                if (!this.socket || this.socket.readyState === 3) {
                    this.socket = new WebSocket(this.address + '/' + this.ctoken)
                    this.socket.onopen = () => {
                        this.pushItem({
                            cmm: 's'
                            , mt: 'CT00100004'
                            , sno: this.user.member_number
                            , snick: this.user.nickname
                            , ctt: '채팅에 참여하였습니다.'
                        })
                        // this.sendSystemIn('@::/채팅에 참여하였습니다.')
                        this.disabled = false
                    }
                    this.socket.onerror = (error) => {
                        throw error
                    }
                    this.socket.onmessage = ({data}) => {
                        this.onMessage(data)
                        this.ping = 0
                    }
                    this.socket.onclose = (msg) => {
                        console.log('socket close', msg)
                        switch (msg.code) {
                            case 1006:
                                this.pushItem({
                                    cmm: 's'
                                    , mt: 'CT00100001'
                                    , sno: ''
                                    , snick: ''
                                    , ctt: '연결이 종료되었습니다.'
                                })

                                if (this.try <= 5) {
                                    setTimeout(() => {
                                        this.connect()
                                        this.try++
                                    }, 1000)
                                }
                                break;
                            default:

                                this.$bus.$emit('notify', {type: 'error', message: msg.reason})
                                break;
                        }
                    }
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.$bus.$emit('on', false)
            }
        }
        , userOut: function (sno) {
            if(this.item_live.chttg_room_progress_member_number === sno) {
                this.offStream()
            }
            this.getData()
        }
        , onMessage: function (data) {
            data = JSON.parse(data)
            console.log('onMessage', data)
            console.log('onMessage', data.mt)
            this.getParticipant()
            switch (data.mt) {
                // 채팅방 입장
                case 'CT00100004':
                    console.log('onStageCT00100004', data)
                    this.srurl = data.srurl // 스트리밍 수신 URL
                    this.srt = data.srt // 스트리밍 수신 토큰
                    this.spt = data.spt // 스트리밍 참여 토큰

                    this.onStage({
                        sk: this.sk,
                        surl: this.surl,
                        srurl: this.srurl,
                        srt: this.srt,
                        spt: this.spt
                    })
                    break;

                // 퇴장 메시지
                case 'CT00100010':
                    this.pushItem({
                        cmm: 's'
                        , mt: 'CT00100010'
                        , sno: data.sno
                        , snick: data.snick
                        , ctt: data.snick + '님이 퇴장하셨습니다.'
                    })
                    this.userOut(data.sno)

                    break;

                // 전체 메시지
                case 'CT00100002':
                    if (this.user.member_number != data.sno) {

                        this.pushItem({
                            cmm: data.cmm
                            , mt: data.mt
                            , sno: data.sno
                            , snick: data.snick
                            , ctt: data.ctt
                        })

                    }
                    break;
                // 개인 메시지
                case 'CT00100003':
                    break;
                // 설정 변경
                case 'CT00100005':
                    this.onSetting(data.ctt)
                    this.pushItem({
                        cmm: 's'
                        , mt: 'CT00100005'
                        , sno: ''
                        , snick: ''
                        , ctt: '채팅방 설정이  변경되었습니다.'
                    })
                    break;
                // 사용자 권한 변경
                case 'CT00100006':
                    break;
                // 스트리밍 권한 변경
                case 'CT00100007':
                    break;
                // 채팅방 종료
                case 'CT00100008':
                    if(!this.is_chat_off) {
                        this.is_chat_off = true
                        this.socket.close()
                    }

                    break;
                case 'CT00100009':
                    this.getData()

                    this.pushItem({
                        cmm: 's'
                        , mt: data.mt
                        , sno: data.sno
                        , snick: data.snick
                        , ctt: data.ctt
                    })
                    break;
                // 스트리밍 시작
                case 'CT00100011':
                    console.log('onStageCT00100011', data)
                    this.cmm = data.cmm // s = 개설자, m = 참여자
                    this.sk = data.sk // 스트리밍 KEY
                    this.surl = data.surl // 스트리밍 엔드포인트
                    this.srurl = data.srurl // 스트리밍 수신 URL
                    this.srt = data.srt // 스트리밍 수신 토큰
                    this.spt = data.spt // 스트리밍 참여 토큰

                    this.onStream({
                        sk: this.sk,
                        surl: this.surl,
                        srurl: this.srurl,
                        srt: this.srt,
                        spt: this.spt
                    })
                    break;
                // 스트리밍 종료
                case 'CT00100012':
                    console.log('stream off', data)
                    this.offStream()

                    break;

                // 참여자 강퇴
                case 'CT00100013':
                    console.log('강퇴당하셨습니다.')
                    this.is_compulsory = true
                    this.is_out = true

                    break;
            }
        }
        , sendSystemIn(msg) {
            let send_message = {
                cmm: 'm'
                , mt: 'CT00100002'
                , sno: this.user.member_number
                , snick: this.user.nickname
                , ctt: msg
            }
            console.log('sendSystemIn', send_message)
            this.socket.send(JSON.stringify(send_message))
            this.ping = 0
        }
        , sendMessage(msg) {
            this.item_chat = msg
            if (this.item_chat) {
                let send_message = {
                    cmm: 'm'
                    , mt: 'CT00100002'
                    , sno: this.user.member_number
                    , snick: this.user.nickname
                    , ctt: this.item_chat
                }
                console.log('sendMessage', send_message)
                this.socket.send(JSON.stringify(send_message))
                this.send(send_message)
                this.ping = 0
            }
        }
        , sendSystemMessage(data) {

            let send_message = {
                cmm: 's'
                , mt: 'CT00100005'
                , sno: this.user.member_number
                , snick: this.user.nickname
                , ctt: data
            }
            console.log('sendSystemMessage', send_message)
            this.socket.send(JSON.stringify(send_message))
        }

        , disconnect() {
            if (this.socket.readyState === 1) {
                this.socket.close()
                this.disabled = true

                this.sendSystemIn("채팅방을 나갔습니다")
            }
        }
        , send: function (data) {
            this.item_chat = ''
            this.pushItem(data)
        }

        , pushItem: function (item) {
            this.items.push(item)


        }
        , onPing: function () {
            console.log('ping', this.ping)
            clearTimeout(this.ping_timeout)
            this.ping_timeout = setTimeout(() => {
                this.ping++
                if (this.ping >= 58) {
                    this.sendPong()
                } else {
                    this.onPing()
                }
            }, 1000)
        }
        , sendPong: function () {
            let send_message = {
                cmm: 's'
                , mt: 'CT00100010'
                , sno: ''
                , snick: ''
                , ctt: 'pong'
            }
            console.log('sendPong', send_message)
            this.socket.send(JSON.stringify(send_message))
            this.ping = 0
        }
        , getParticipant: async function (keyword) {
            try {
                const result = await this.$Request({
                    method: 'post'
                    , url: this.$api_url.api_path.get_cartel_live_participation_list
                    , data: {
                        member_number: this.user.member_number
                        , cartl_number: this.$route.params.idx
                        , cartl_chttg_room_number: this.$route.params.live_idx
                        , srchtext: keyword
                    }
                    , type: true
                })

                if (result.success) {
                    this.participant_list = result.data.participation_member_list

                    console.log(result)
                    console.log(this.participant_list,'this.participant_list')

                } else {
                    throw result.message
                }
            } catch (e) {
                console.log(e)
                this.$bus.$emit('notify', {type: 'error', message: e})
            } finally {
                this.$bus.$emit('on', false)
            }
        }
    }
    , async created() {
        await this.getData()
        await this.getCartel()
        await this.postLiveIn()
        await this.connect()
    }
    , beforeDestroy() {
        this.socket.close()
        console.log('beforeDestroy')
    }
    , watch: {
        is_out: {
            handler: function (call) {
                console.log(call, 'is_out')
                if (call) {
                    this.socket.close()
                }
            }
        }
        , item_live_in: {
            immediate: true
            , deep: true
            , handler: (call) => {
                console.log('live detail item_live_in', call)
            }
        }
    }
}
</script>

<style>
.on {
    background-color: transparent;
}
</style>