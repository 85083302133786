<template>
	<div
		ref="post_list_item_view"
		style="min-height: 480px"
	>
		<div
			v-if="list_bbs.length > 0"
		>
			<ul
				class=""
			>
				<li
					v-for="(bbs_info, b_index) in list_bbs"
					:key="'bbs_' + b_index"
					class="bg-white radius-20 mb-30"
				>
					<div class="flex-row justify-space-between pa-20 under-line">
						<div class="flex-row align-center">
							<ProfileImage
								:item_info="bbs_info"
								:cartel_info="{}"
								icon_type="ss"
							></ProfileImage>
							<div class="font-weight-500 color-333">
								{{  bbs_info.post_member_nickname ? bbs_info.post_member_nickname : bbs_info.nickname }}
								<span v-if="bbs_info.is_block" class="color-red">차단</span>
							</div>
						</div>
						<div
							class="color-888 flex-row align-center gap-10 size-px-14"
						>
							{{  bbs_info.time_t }}
							<button
								@click="onMore(bbs_info, b_index)"
								class="post_set"
							><v-icon>mdi-dots-vertical</v-icon></button>
						</div>
					</div>

					<div class="under-line">
						<template
							v-if="!bbs_info.is_block"
						>
							<div class="text-center">
								<CartelBbsFile
									v-if="bbs_info.post_file_list && bbs_info.post_file_list.length > 0"
									:user="user"
									:item="bbs_info"
									:index="b_index"

									@toDetail="toDetail(bbs_info, b_index)"
								></CartelBbsFile>
							</div>

							<div
								class=" pa-20 size-px-14 color-333 cursor-pointer"
								@click="toDetail(bbs_info, b_index)"
							>
								<div>
									<label
										v-if="bbs_info.vote_fg == 'Y'"
										class="label label-primary radius-20 pa-5-10"
									>투표</label>
								</div>
								<div class="mt-10">
									<div
										v-if="bbs_info.board_type_code == 'CA00700001'"
										class="mb-10"
									>{{ bbs_info.post_title }}</div>
									{{  bbs_info.contents | maxLength(90, '...')}}

									<span
										v-if="!bbs_info.is_block && !bbs_info.is_blur"
										class="ml-10 color-888  size-px-14"
									> 더보기 </span>
								</div>
							</div>
						</template>

						<div
							v-else
							class=" pa-20 color-888 cursor-pointer"
						>차단된 글입니다</div>

						<div class=" pa-20 color-888 position-relative size-px-14">
							<span class="mr-10">공감 <span class="font-weight-600">{{  bbs_info.sympaty_count }}</span>명</span>
							<span>댓글 <span class="font-weight-600">{{  bbs_info.comment_count }}</span>개</span>

							<div
								v-if="bbs_info.is_emoji"
								class="emotion_view" style="position: absolute; top: 0px; left: 0"
							>
								<div class="emotion_select_view">
									<div class="list" style="display: flex;">
										<!-- 선택된 표정의 버튼에는 on 클래스 추가 -->
										<button
											v-for="(emoji, index) in icons_emoji"
											:key="'emoji_icon_' + index"
											type="button" class="btn_emotionitem" data-emotion-type="best"
											:style="bbs_info.sympaty_icon_code == emoji.code ? 'background-color: #bbb; border-radius: 50px': ''"
											@click="postEmoji(bbs_info, emoji)"
										>
											<span
												class="faceicon_30  em_select_btn"
												:class="'f_' + emoji.type + '_30'"

											>{{  emoji.txt }}</span>
										</button>
									</div>
									<span class="list_arrow"></span>
								</div>
							</div>
						</div>
					</div>

					<div class="flex-row justify-space-between  pa-20">
						<div>
							<button
								@click="postEmoji(bbs_info, { code: 'CA01100001'})"
							><span class="add_status add_empathy " :class="{ on: bbs_info.is_empathy}">공감</span></button>
						</div>
						<div>
							<a
								@click="toDetail(bbs_info, b_index)"
							><span class="add_status add_comment">댓글</span></a>
						</div>
					</div>
				</li>
			</ul>

			<ScrollPagination
				v-if="items_list.length > 0 && $refs.post_list_item_view"
				:body="$refs.post_list_item_view"
				:item_search="item_search"
				:max="max"
				:is_add="is_add"

				@change="$emit('change', $event)"
			></ScrollPagination>
		</div>

		<div
			v-else
			class="bg-white radius-20 mb-30 pt-30"
		>
			<div
				class="none "
			>등록된 게시물이 없습니다</div>
		</div>

		<mafia064
			v-if="is_more"

			:user="user"
			:item_bbs="item_more"

			@cancel="is_more = false"
			@deleteBbs="deleteBbs"
			@move="moveBbs"
			@modify="toModify"
			style="z-index: 1003"
		>
		</mafia064>

		<!-- 구독 열람 권한 -->
		<PopupConfirm
			v-if="is_on_subscribe_permission"
			@click="onSubscribe"
			@cancel="is_on_subscribe_permission = false"
		>
			<template v-slot:title>{{  $language.cartel_new.title_subscribe_permission }} <!-- 열람권한 확인 --> </template>
			<template
				v-slot:main-txt
			>{{  $language.cartel_new.txt_subscribe_permission_main }}  <!-- 열람권한이 없습니다. --></template>
			<template
				v-slot:sub-txt
			>{{  $language.cartel_new.txt_subscribe_permission_sub }}  <!-- 해당 게시판의 열람권한을 위해 구독 플랜을 구입하세요. --></template>
			<template
				v-slot:name-cancel
			>{{  $language.common.cancel }}</template>
			<template
				v-slot:name-confirm
			>{{  $language.cartel_new.title_subscribe }} <!-- 구독하기 --></template>
		</PopupConfirm>

		<!-- 카르텔 구독 플랜 -->
		<CartelSubscribe
			v-if="is_on_subscribe"
			:user="user"
			:cartel_number="item_more.cartl_number"
			:plan_info="item_cartel.member_cartl_subscrp_plan_info"

			@cancel="offSubscribe"
		></CartelSubscribe>
	</div>
</template>

<script>

	import Mafia064 from "@/view/Cartel/mafia064";
	import ScrollPagination from "@/components/ScrollPagination";
	import ProfileImage from "@/components/Daum/ProfileImage";

	import CartelBbsFile from "@/view/Cartel/CartelBbsFile";
	import PopupConfirm from "@/view/Layout/PopupConfirm";
	import CartelSubscribe from "@/view/SubscribeCartel/CartelSubscribe";

	export default {
		name: 'CartelBbsList'
		, components: {CartelSubscribe, PopupConfirm, CartelBbsFile, ProfileImage, ScrollPagination, Mafia064}
		, props: ['user', 'items_list', 'item_search', 'max', 'is_add', 'body', 'cartel']
		, data: function(){
			return {
				is_more: false
				, is_delete: false
				, item_more: {}
				, is_item_detail: false
				, is_item_modify: false

				, icons_emoji: [

					{ code: 'CA01100001', type: 'best', txt: this.$language.emoticon.best}
					, { code: 'CA01100002', type: 'funny', txt: this.$language.emoticon.funny}
					, { code: 'CA01100003', type: 'like', txt: this.$language.emoticon.like}
					, { code: 'CA01100004', type: 'sad', txt: this.$language.emoticon.sad}
					, { code: 'CA01100005', type: 'surprise', txt: this.$language.emoticon.surprise}
					, { code: 'CA01100006', type: 'angry', txt: this.$language.emoticon.angry}
				]
				, is_on_subscribe_permission: false
				, is_on_subscribe: false
				, item_cartel: {}
			}
		}
		, computed: {
			list_bbs: function(){

				return this.items_list.filter( async (item) => {
					if(item.blok_member_fg == 'Y'){
						item.is_block = true
					}else{
						item.is_block = false
					}
					if(item.sympaty_icon_code != ''){
						item.is_empathy = true
					}
					item.time_t = this.$date.getTimeStory(item.registdate)

					if(item.board_type_code == 'CA00700005' && (item.member_post_subscrp_fg != 'Y' && item.member_cartl_subscrp_fg != 'Y')){
						item.is_blur = true
						if(item.bdy_release_fg != 'Y'){
							item.contents = '잠긴 컨텐츠'
							item.is_lock_body = true
						}
					}
					if(item.board_type_code == 'CA00700001' || item.board_type_code == 'CA00700002'){
						item.contents = item.post_contents
					}

					if(item.post_file_list && item.post_file_list.length > 0){
						for(let i = 0; i < item.post_file_list.length; i++){
							let file = item.post_file_list[i]
							// item.post_file_list[i].file_url = item.post_file_list[i].post_appendix_file_partial_list[0].appendix_file_url
							// file.file_url = file.post_appendix_file_org_url

							switch (file.appendix_file_div_code) {
								case 'CA01000001':
									file.is_image = true
									break;
								case 'CA01000002':
									file.is_video = true
									break;
								case 'CA01000003':
									file.is_audio = true
									break;
							}
							if(file.appendix_file_div_code){
								file.post_appendix_file_div_code = file.appendix_file_div_code
							}

							switch (file.post_appendix_file_div_code) {
								case 'CA01000001':
									file.is_image = true
									break;
								case 'CA01000002':
									file.is_video = true
									break;
								case 'CA01000003':
									file.is_audio = true
									break;
							}

							// console.log(item.bulletin_number, file.post_appendix_file_div_code, file.post_appendix_file_org_url)

							let p_files = file.post_appendix_file_partial_list

							if(p_files && p_files.length > 0){

								let p_file = file.post_appendix_file_partial_list[0]
								file.cartl_number = item.cartl_number
								file.board_number = item.board_number
								file.bulletin_number = item.bulletin_number
								file.appendix_file_div_code = file.post_appendix_file_div_code
								if(file.is_image){
									file.appendix_file_url = p_file.appendix_file_url + (p_file.appendix_file_name ? '/' + p_file.appendix_file_name : '')
								}else{
									file.appendix_file_url = p_file.appendix_file_url
								}
								file.img_pay_div_code = file.post_appendix_file_img_pay_div_code
							}else{

								file.cartl_number = item.cartl_number
								file.board_number = item.board_number
								file.bulletin_number = item.bulletin_number
								file.appendix_file_div_code = file.post_appendix_file_div_code
								if(file.file_url){
									file.post_appendix_file_org_url = file.file_url
								}
								let file_url = file.file_url ? file.file_url : file.post_appendix_file_org_url

								if(file.is_image) {
									file.appendix_file_url = file_url + (file.post_appendix_file_name ? '/' + file.post_appendix_file_name : '')
								}else{
									file.appendix_file_url = file_url
								}

								file.img_pay_div_code = file.post_appendix_file_img_pay_div_code

							}
						}

					}

					return item
				})
			}
		}
		, methods: {
            openLive() {
                this.$emit('openLive')
            }
			, toDetail: function(item, index){

				this.item_more = item
				this.$set(this.item_more, 'index', index)

				if(!item.is_block){
					if(item.board_type_code == 'CA00700005' && (item.member_post_subscrp_fg != 'Y' && item.member_cartl_subscrp_fg != 'Y')){

						this.getCartel(item.cartl_number)
					}else{

						let to_name = 'mafia058'

						this.$bus.$emit('to', { name: to_name,
							params: {
								idx: this.$route.params.idx ? this.$route.params.idx : item.cartl_number,
								code: this.$route.params.code ? this.$route.params.code : item.board_type_code,
								b_id: this.$route.params.b_id ? this.$route.params.b_id : item.board_number,
								bbs_id: this.$route.params.bbs_id ? this.$route.params.bbs_id : item.bulletin_number,
								type: null
							},
							hash: 'detail'
						})
					}
				}
			}
			, onMore: function(item, index){
				this.is_more = true
				this.item_more = item
				this.item_more.index = index
			}

			, deleteBbs: async function(){
				this.is_more = false
				this.is_item_detail = false
				this.items_list.splice(this.item_more.index, 1)
			}
			, moveBbs: async function(){
				this.is_more = false
				this.is_delete = false
				await this.mount()
			}
			, toModify: function(){
				this.$bus.$emit('to', {name: 'mafia1272', params: { idx: this.item_more.cartl_number, code: this.item_more.board_type_code, b_id: this.item_more.board_number}})
			}
			, onModify: function(){
				this.is_item_modify = true
			}

			, postEmoji: async function(item, emoji){
				try{
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_emoji
						, data: {
							member_number: this.user.member_number
							, cartl_number: item.cartl_number ? item.cartl_number : this.$route.params.idx
							, board_number: item.board_number
							, bulletin_number: item.bulletin_number
							, sympaty_icon_code: emoji.code
						}
						, type: true
					})

					if(result.success){
						item.is_empathy = emoji.code
						item.sympaty_icon_code = emoji.code
						item.is_emoji = false
						item.sympaty_count++
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_emoji = false
					this.$bus.$emit('on', false)
				}
			}

			, onSubscribe: function(){
				this.is_on_subscribe_permission = false
				this.is_on_subscribe = true
			}
			, offSubscribe: function(){
				this.is_on_subscribe = false
			}
			, getCartel: async function(idx){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: idx
							, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
							, post_list_page: { page_number: 1, pagerecnum: 10}
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						this.is_on_subscribe_permission = true
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			// document.body.scrollTop = 0
		}
	}
</script>

<style>
	.box-size-slide { height: auto; min-height: 300px; max-height: 800px; overflow: hidden}
</style>