<template>
	<div
		v-if="items.length == 1"
	>
		<div
			v-for="(file, f_index) in items"
			:key="'item_' + index + '_file_' + f_index"
			class="text-center position-relative"
		>
			<template
				v-if="item.is_blur"
			>
				<div
					class="position-absolute-full flex-column justify-center items-center"
					style="z-index: 999"
					@click="$emit('toDetail', item, index)"
				>
					<img
						:src="require('@/assets/image/ico_lock.png')"
						class="width-10"
					>
					<div class="color-white mt-10 size-px-16 text-shadow">구독 서비스 가입 후 이용해주세요</div>
				</div>

				<img
					v-for="(part, p_index) in file.parts"
					:key="'part_' + p_index"
					:src="part.file_url"
					@error="$bus.$emit('onErrorCartelCharacterImage', $event)"
					:alt="file.post_appendix_file_indexnumber"
					class="object-cover"
				/>
			</template>

			<template
				v-else
			>
				<template
					v-if="file.is_image"
				>
					<img
						v-for="(part, p_index) in file.parts"
						:key="'part_' + p_index"
						:src="part.file_url"
						@click="$emit('toDetail', item, index)"
						@error="$bus.$emit('onErrorImage', $event)"
						:alt="file.post_appendix_file_indexnumber"
						class="object-cover"
					/>
				</template>
				<video
					v-if="file.is_video"
					width="100%"
					controls
				>
					<source
						v-for="(part, p_index) in file.parts"
						:key="'part_' + p_index"
						:src="part.file_url"
						type="video/webm"
					>
				</video>
				<div
					v-if="file.is_audio"
					class="text_view"
					style="position: static !important; padding: 0; width: 100%;"
				>
					<figure class="audio_wrap">
						<div class="audio_bg">
							<span class="hide">
								{{ $language.common.soundBackgroundImg }}
							</span>
						</div>
						<div class="bg-mafia-blue">
							<video
								width="90%"
								controls
								height="350px"
							>
								<source

									v-for="(part, p_index) in file.parts"
									:key="'part_' + p_index"
									:src="part.file_url"
									style="width: 50%"
								>
							</video>
						</div>
					</figure>
				</div>
			</template>
		</div>
	</div>
	<carousel
		v-else
		:perPage="1"
	>
		<slide
			v-for="(file, f_index) in items"
			:key="'item_' + index + '_file_' + f_index"
			class="photo_collage_view flex-column justify-center items-center"
		>
			<template
				v-if="file.is_image"
			>
				<div
					v-if="item.is_blur"
					:class="{blur: item.is_blur}"
				>
					<img
						v-for="(part, p_index) in file.parts"
						:key="'part_' + p_index"
						:src="part.file_url"
						@error="$bus.$emit('onErrorImage', $event)"
						:class="part.file_url"
						class="object-cover"
					/>
				</div>

				<img
					v-else
					v-for="(part, p_index) in file.parts"
					:key="'part_' + p_index"
					:src="part.file_url"
					@error="$bus.$emit('onErrorImage', $event)"
					class="width-100"
					:class="item.post_appendix_file_indexnumber"
					:alt="item.post_appendix_file_indexnumber"
				/>
			</template>

			<template
				v-else
			>
				<div
					v-if="item.is_blur"
					class="position-relative full-height"
				>
					<div
						class="position-absolute-full flex-column justify-center items-center"
						style="z-index: 999"
						@click="$emit('toDetail', item, index)"
					>
						<img
							:src="require('@/assets/image/ico_lock.png')"
							class="width-10"
						>
						<div class="color-white mt-10 size-px-16">구독 서비스 가입 후 이용해주세요</div>
					</div>

					<img
						:src="file.parts[0].file_url"
						@error="$bus.$emit('onErrorCartelCharacterImage', $event)"
						class="object-cover"
					/>
				</div>

				<template
					v-else
				>
					<video
						v-if="file.is_video"
						width="100%"
						controls
					>
						<source
							v-for="(part, p_index) in file.parts"
							:key="'part_' + p_index"
							:src="part.file_url"
							type="video/webm"
						>
					</video>
					<div
						v-if="file.is_audio"
						class="text_view"
						style="position: static !important; padding: 0; width: 100%"
					>
						<figure class="audio_wrap">
							<div class="audio_bg">
							<span class="hide">
								{{ $language.common.soundBackgroundImg }}
							</span>
							</div>
							<div class="bg-gray">
								<video
									width="100%"
									controls
									height="40px"
								>
									<source
										v-for="(part, p_index) in file.parts"
										:key="'part_' + p_index"
										:src="part.file_url"
									>
								</video>
							</div>
						</figure>
					</div>
				</template>
			</template>
		</slide>
	</carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import {encodeStorage} from "@/resources/storage/localStorage";
export default {
	name: 'CartelBbsFile'
	, props: ['user', 'item', 'index']
	, components: {Carousel, Slide}
	, data: function(){
		return {
			items: []
		}
	}
	, methods: {

		getRealFile: async function(item, index){
			let domain = process.env.VUE_APP_DOMAIN
			let domain_m = process.env.VUE_APP_DOMAIN_M
			let domain_stg = process.env.VUE_APP_DOMAIN_STG
			let domain_stgm = process.env.VUE_APP_DOMAIN_STG_M
			let domain_dev = process.env.VUE_APP_DOMAIN_DEV
			let domain_devm = process.env.VUE_APP_DOMAIN_DEV_M
			let dev = process.env.VUE_APP_DEV
			let server = process.env.VUE_APP_SERVER
			let stg = process.env.VUE_APP_STG
			let local = process.env.VUE_APP_LOCAL
			let location = window.location.href

			let baseUrl = ''

			this.$set(item, 'is_image', item.post_appendix_file_div_code == 'CA01000001' ? true : false)
			this.$set(item, 'is_video', item.post_appendix_file_div_code == 'CA01000002' ? true : false)
			this.$set(item, 'is_audio', item.post_appendix_file_div_code == 'CA01000003' ? true : false)

			// 운영계 API 호출
			if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1) {
				baseUrl = server

				// 검증계 API 호출
			}else if(location.indexOf(domain_stgm) > -1 || location.indexOf(domain_stg) > -1){
				baseUrl = stg

				// 개발계 API 호출
			}else if(location.indexOf(domain_dev) > -1 || location.indexOf(domain_devm) > -1){
				baseUrl = dev

				// 로컬 API 호출
			}else{
				baseUrl = local
			}

			let url = baseUrl + '/' + this.$api_url.api_path.get_board_file_stream
			if(!item.is_image){
				url = baseUrl + '/' + this.$api_url.api_path.get_board_file_stream
			}
			let default_header = {
				'Content-Type': 'application/json'
				, 'v': '1.0.0'
				, 'Content-Security-Policy' : 'upgrade-insecure-requests'
			}

			let d = url.split('/::')
			if(d.length > 1){
				default_header.v = d[1]
				url = d[0]
			}

			let session_token = encodeStorage.getSessionToken()
			//console.log('session_token', session_token)

			if(session_token){
				default_header.a = session_token
				//console.log('default_header', default_header)
			}

			let size = 1;
			if(this.$route.params.bbs_id == item.bulletin_number){
				size = item.post_appendix_file_partial_list.length > 0 ? item.post_appendix_file_partial_list.length : 1
			}

			this.$set(this.items, index, {
				parts: []
				, is_image: item.is_image
				, is_video: item.is_video
				, is_audio: item.is_audio
			})

			for(let i = 0; i < size; i++){

				let data = {
					member_number: this.user.member_number
					, cartl_number: this.$route.params.idx ? this.$route.params.idx : item.cartl_number
					, board_number: this.$route.params.b_id ? this.$route.params.b_id : item.board_number
					, bulletin_number: item.bulletin_number
					, appendix_file_div_code: item.appendix_file_div_code
					, appendix_file_url: item.post_appendix_file_partial_list?.length > 0 ? item.post_appendix_file_partial_list[i].appendix_file_url + '/' + item.post_appendix_file_partial_list[i].appendix_file_name : item.post_appendix_file_org_url
					, img_pay_div_code: item.img_pay_div_code
				}

				data = new URLSearchParams(data).toString()

				await fetch(url + '?' + data, {
					headers: default_header
				})
					.then((response) => response.body)
					.then((body) => {
						const reader = body.getReader();

						return new ReadableStream({
							start(controller) {
								return pump();

								function pump() {
									return reader.read().then(({ done, value }) => {
										// When no more data needs to be consumed, close the stream
										if (done) {
											controller.close();
											return;
										}

										// Enqueue the next data chunk into our target stream
										controller.enqueue(value);
										return pump();
									});
								}
							},
						});
					})
					.then((stream) => new Response(stream))
					.then((response) => response.blob())
					.then((blob) => {

						let reader = new FileReader()
						reader.onload = (e) => {
							if(e.target.result){
								this.$set(this.items[index].parts, i, {
									file_url: e.target.result
								})
							}else{
								this.$set(this.items[index].parts, i, {
									file_url: 'fail'
								})
							}
						}

						reader.readAsDataURL(blob)
					})
					.catch(() => {
						this.$set(this.items[index].parts, i, {
							file_url: 'error'
						})
					});
			}
		}

		, mount: async function(){
			this.items = []

			if(this.item.post_file_list && this.item.post_file_list.length > 0) {

				for(let [i, file] of this.item.post_file_list.entries()){

					if(this.item.board_type_code == 'CA00700005'){
						if(this.item.member_post_subscrp_fg == 'Y'){
							await this.getRealFile(file, i)
						}else{
							if(file.appendix_file_div_code == 'CA01000001'){
								await this.getRealFile(file, i)
							}else{
								this.items.push({
									parts: [
										{ file_url: file.post_appendix_file_org_url }
									]
									, is_image: file.post_appendix_file_div_code == 'CA01000001' ? true : false
									, is_video: file.post_appendix_file_div_code == 'CA01000002' ? true : false
									, is_audio: file.post_appendix_file_div_code == 'CA01000003' ? true : false
								})
							}
						}
					}else{

						this.items.push({
							parts: [
								{ file_url: file.post_appendix_file_org_url }
							]
							, is_image: file.post_appendix_file_div_code == 'CA01000001' ? true : false
							, is_video: file.post_appendix_file_div_code == 'CA01000002' ? true : false
							, is_audio: file.post_appendix_file_div_code == 'CA01000003' ? true : false
						})
					}

				}
			}
		}
	}

	, async created() {
		await this.mount()
	}
	, watch: {
		async $route() {
			await this.mount()
		}
	}
}
</script>