<template>
	<div
		ref="post_list_item_view"
		class="mt-30 cartel"
	>
		<div
			class="flex-row text-left"
		>
			<div
				class="box-list"
			>
				<div class="bg-white pa-20 radius-20">
					<div class="flex-row justify-space-between">
						<h4>사용자 검색 결과</h4>
						<v-icon
							v-if="view_type == 'member'"
							@click="view_type = ''"
						>mdi-close-circle</v-icon>
						<button
							v-else
							@click="view_type = 'member'"
						><img :src="require('@/assets/image/web/index_cartel/ico_arrow_right_lg.svg')" style="width: 10px"></button>
					</div>
					<div
						class="mt-30 "
						style="overflow: hidden;white-space: nowrap"
					>
						<ul
							v-if="items_member.length > 0"
						>
							<li
								v-for="(item, index) in items_member"
								:key="'item_' + index"
								class="inline-block mr-20 text-center"
								@click="on098(item)"
							>
								<div class="picture">
									<img
										v-if="item.profle_nft_card_img_url"
										:src="$request.upload_url(item.profle_nft_card_img_url)" :alt="item.nickname"
									>
									<img
										v-else
										:src="require('@/assets/image/@noimage.png')" :alt="item.nickname"
									>
								</div>
								<div class="mt-10">
									<strong>{{ item.nickname }}</strong>
								</div>
							</li>
						</ul>
						<div
							v-else
							class="none"
						>검색된 사용자가 없습니다</div>
					</div>
				</div>

				<ul
					v-if="items_bbs.length > 0"
					class="mt-30"
				>
					<li
						v-for="(item, index) in items_bbs"
						:key="'item_' + index"
						class="bg-white radius-20 mb-30"
					>
						<div class="flex-row justify-space-between pa-20 under-line">
							<div class="flex-row">
								<span  class="inline-block icon icon-30 color-black mr-10">
									<img
										v-if="item.profle_nft_card_img_url"
										:src="$request.upload_url(item.profle_nft_card_img_url)"
									/>
									<img
										v-else
										:src="require('@/assets/image/@profile_inner.png')" alt="사진없음"
									>
								</span>
								<div class="flex-column justify-center font-weight-500 color-333" style=""><em v-if="item.is_block" class="state-block">차단</em>{{ item.nickname ? item.nickname : item.post_member_nickname }}</div>
							</div>
							<div
								class="color-888 flex-row align-center gap-10 size-px-14"
							>
								{{  item.time_t }}
								<button
									@click="onMore(item, index)"
									class="post_set"
								><span class="hide">설정</span></button>
							</div>
						</div>
						<div class="under-line">
							<div class="text-center">
								<carousel
									:perPage="1"
								>
									<slide
										v-for="(file, f_index) in item.post_file_list"
										:key="'item_' + index + '_file_' + f_index"
										class="photo_collage_view bg-black "
										style=" max-height: 350px; overflow: hidden"
									>
										<div
											v-if="file.appendix_file_div_code.indexOf('CA01000001') > -1"
											class="flex-column justify-center full-height"
										>
											<div
												style="object-fit: cover;"
											>
												<img
													:src="$request.upload_url(file.file_url)"
												/>
											</div>
										</div>
										<video
											v-if="file.appendix_file_div_code.indexOf('CA01000002') > -1"

											controls
											style="object-fit: cover; max-height: 350px"
										>
											<source
												:src="file.file_url"
											>
										</video>
										<div
											v-if="file.appendix_file_div_code.indexOf('CA01000003') > -1"
											class="flex-column " style="position: static !important;"
										>
											<figure class="audio_wrap" style="height: 300px;">
												<div class="audio_bg">
													<span class="hide">사운드배경이미지</span>
												</div>
											</figure>
											<div style="width: 480px; overflow: hidden; margin: 0 auto">
												<video
													controls
													style="width: 100%; height: 50px; padding: 10px"
												>
													<source
														:src="file.file_url"
													>
												</video>
											</div>
										</div>
									</slide>
								</carousel>
							</div>

							<div
								class=" pa-20"
								@click="toDetail(item, index)"
							>

								<button
									v-if="item.vote_fg == 'Y'"
									class="btn-inline btn-primary radius-20 pa-5-10"
								>투표</button>
								{{  item.contents }}
							</div>

							<div
								class=" prl-20 color-gray cursor-pointer"
								@click="toDetail(item, index)"
							>
								더보기
								<button
									v-if="item.vote_fg == 'Y'"
									class="btn-inline btn-blue radius-20 pa-5-10"
								>투표</button>
							</div>
							<div class=" pa-20 color-gray">
								<span class="mr-10">공감 <span>{{  item.sympaty_count }}</span>명</span>
								<span>댓글 <span>{{  item.comment_count }}</span>개</span>
							</div>
						</div>
						<div class="flex-row justify-space-between  pa-20">
							<div>
								<button
									@click="$set(item, 'is_emoji', !item.is_emoji)"
								><span class="add_status add_empathy " :class="{ on: item.is_empathy}">공감</span></button>
							</div>
							<div>
								<a
									@click="toDetail(item, index)"
								><span class="add_status add_comment">댓글</span></a>
							</div>
						</div>
					</li>
				</ul>

				<div
					v-else

					class="mt-30 bg-white radius-20 pa-20"
				>
					<h4>게시물 검색 결과</h4>
					<div
						class="none"
					>검색 결과가 없습니다</div>
				</div>


			</div>

			<CartelEtc
				:user="user"
				:cartel_info="item_cartel"
				:bbs_info="{}"
			></CartelEtc>
		</div>

		<mafia098
			v-if="is_098"
			:cartel="items_bbs"
			:user="user"
			:friend="item_user"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 99; background-color: white"
		></mafia098>

		<ScrollPagination
			v-if="$refs.post_list_item_view"
			:body="$refs.post_list_item_view"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"

			@change="getBbsList"
		></ScrollPagination>

		<mafia064
			v-if="is_more"
			@cancel="is_more = false"
			@move="mount"
			@deleteBbs="mount"
			@modify="onModify"

			:user="user"
			:item_bbs="item_more"
			style="z-index: 1003"
		>
		</mafia064>

		<mafia1272
			v-if="is_item_modify"

			:user="user"
			:cartel="cartel"
			:bbs="item_more"

			@cancel="is_item_modify = false"
			@click="modify"
			class="layer-popup"
		></mafia1272>

		<cartel_bbs_detail
			v-if="is_item_detail"

			:cartel="cartel"
			:user="user"
			:bbs="item_more"
			:is_item_detail_comment="is_item_detail_comment"

			@cancel="update"
			@toModify="onModify"
			@deleteBbs="deleteBbs"
			class="layer-popup"
		></cartel_bbs_detail>
	</div>
</template>

<script>

import mafia098 from "@/view/Cartel/mafia098"
import { Carousel, Slide } from 'vue-carousel';
import ScrollPagination from "@/components/ScrollPagination";
import mafia064 from "@/view/Cartel/mafia064";
import cartel_bbs_detail from "@/view/Cartel/cartel_bbs_detail";
import mafia1272 from "@/view/CartelBoard/mafia127-2";
import CartelEtc from "@/view/Cartel/CartelEtc";

export default {
	name: 'mafia048'
	, props: ['user', 'cartel']
	, components: {mafia064, mafia098, Carousel, Slide, ScrollPagination, cartel_bbs_detail, mafia1272, CartelEtc}
	, data: function(){
		return {
			program: {
				name: '카르텔 게시물 검색'
				, title: '검색'
				, not_footer: true
				, bg_contents: 'bg-gray01'
			}
			, item_cartel: {

			}
			, icons_emoji: [

				{ code: 'CA01100001', type: 'best', txt: '최고예요'}
				, { code: 'CA01100002', type: 'funny', txt: '웃겨요'}
				, { code: 'CA01100003', type: 'like', txt: '좋아요'}
				, { code: 'CA01100004', type: 'sad', txt: '슬퍼요'}
				, { code: 'CA01100005', type: 'surprise', txt: '놀랐어요'}
				, { code: 'CA01100006', type: 'angry', txt: '화나요'}
			]
			, item_search: this.$storage.init({
				member_number: this.user.member_number
				, cartl_number: this.$route.params.idx
				, search_value: this.$route.params.search_value
				, page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			})
			, is_search: false
			, items_bbs: []
			, view_type: ''
			, is_098: false
			, item_user: {}
			, is_add: false
			, max: false
			, is_more: false
			, item_more: {}
			, item_bbs: {}
			, items_member: []
			, is_item_detail: false
			, is_item_detail_comment: false
			, is_item_modify: false
			, items_cartel_bbs: []
		}
	}
	, computed: {
		txt_search: function(){
			let t = '검색어를 입력하세요'

			if(this.is_search && this.items_bbs.length == 0){
				t = '검색결과가 없습니다'
			}

			return t
		}
		, text_empty: function (){
			let t = '검색어를 입력하세요.'
			if(this.item_search.search_value && this.items_cartel_bbs){
				t = '검색된 게시물이 없습니다.'
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){

			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: async function(){
			this.item_search.page_number = this.$language.base.page_number
			this.items_bbs = []
			// await this.getCartelSearch()
			await this.getMemberList()
			await this.getBbsList()
		}
		, getCartelSearch: async function(){
			try{
				if(!this.item_search.search_value){
					throw this.$language.common.error_empty_search_value
				}
				this.$set(this.item_search, 'page_number', 1)
				this.is_add = true
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_total_search
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchtext: this.item_search.search_value
					}
					, type: true
				})

				if(result.success){

					this.items_bbs = result.data.cartl_post_search_result
					this.items_member = result.data.user_search_result

					this.$storage.setQuery(this.item_search)

				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		, getBbsList: async function(page = 1){
			try{
				if(!this.item_search.search_value){
					throw this.$language.common.error_empty_search_value
				}
				this.$set(this.item_search, 'page_number', page)
				this.is_add = true
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_bbs_search
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchtext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(result.data.post_list.length > 0){
						this.items_bbs = this.items_bbs.concat(result.data.post_list)
						this.max = false
					}else{
						this.max = true
					}

					this.$storage.setQuery(this.item_search)

				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}

		, getMemberList: async function(page = 1){
			try{
				if(!this.item_search.search_value){
					throw this.$language.common.error_empty_search_value
				}
				this.$set(this.item_search, 'page_number', page)
				this.is_add = true
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_member_search_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchtext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_member = result.data.member_list
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		, goBack: function(){
			if(this.view_type == 'member'){
				this.view_type = ''
			}else{
				this.$emit('goBack')
			}
		}


		, postEmoji: async function(item, emoji){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_emoji
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
						, sympaty_icon_code: emoji.code
					}
					, type: true
				})

				if(result.success){
					item.is_empathy = emoji.code
					item.is_emoji = false
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_emoji = false
				this.$bus.$emit('on', false)
			}
		}
		, onMore: function(item){
			this.is_more = true
			this.item_more = item
		}
		, mount: function(){
			this.is_more = false
			this.getSearch()
		}
		, toDetail: function(item){

			this.$bus.$emit('to', { path: '', name: 'mafia058', params: { idx: this.$route.params.idx, code: item.board_type_code ? item.board_type_code : this.$route.params.code, b_id: item.board_number, bbs_id: item.bulletin_number, from: 'mafia048'}})
		}
		, toComment: function(item){
			this.$emit('to', { name: 'mafiaCommentList', params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, bbs_id: item.bulletin_number}})
		}

		, on098: function(user){
			this.$log.console('user', user)
			this.is_098 = true
			this.item_user = user
		}
		, update: function(item){

			this.item_more.sympaty_icon_code = item.sympaty_icon_code
			this.is_item_detail = false
			this.getBbs(this.item_more)
		}
		, onModify: function(){
			this.is_item_modify = true
		}
		, deleteBbs: async function(){
			this.is_more = false
			this.is_item_detail = false
			this.items_cartel_bbs.splice(this.item_more.index, 1)
		}


		, getCartelBbsInfo: async function(item){
			try{
				if(!item.bulletin_number){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					let bbs = result.data

					this.item_more.contents = bbs.post_contents

					if(bbs.post_file_list.length > 0){
						for(let i = 0; i < bbs.post_file_list.length; i++){
							this.item_more.post_file_list[i].appendix_file_div_code = bbs.post_file_list[i].post_appendix_file_div_code
							this.item_more.post_file_list[i].file_url = bbs.post_file_list[i].post_file_url
						}
					}

					this.item_more.comment_count = bbs.comment_tot_count
					this.item_more.sympaty_count = bbs.sympaty_list.length

					this.$set(this.items_cartel_bbs, this.item_more.index, this.item_more)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelCheerInfo: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_cheer_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: item.cartl_number
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					this.items_cartel_bbs[this.item_more.index] = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBbs: function(item){
			switch (item.board_type){
				case 'CA00700001':
					this.getCartelNoticeInfo(item)
					break
				case 'CA00700002':
					this.getCartelCheerInfo(item)
					break
				default:
					this.getCartelBbsInfo(item)
					break
			}
		}
		, modify: function(item){
			this.is_more = false
			this.is_item_modify = false
			this.$log.console('modify', item)
			this.getBbs(item)
			if(this.is_item_detail){
				this.is_item_detail = false
				setTimeout(() => {
					this.is_item_detail = true
				}, 5)
			}
		}
	}
	, mounted() {
		this.$bus.$emit('onLoad', this.program)

		this.getCartel()
		this.getSearch()
	}
	, watch:{
		$route(to, from){
			this.$log.console(to, from)
			if(to.params.search_value){
				this.item_search.search_value = to.params.search_value
				this.getSearch()
			}
		}
	}
}
</script>

<style scoped>
	.picture {
		width: 120px; height: 120px; overflow: hidden; border-radius: 20px;
	}
	.picture img{
		width: 100%;
		object-fit: cover;
	}
</style>