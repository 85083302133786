<template>
	<div
		class="mb-50"
	>
		<div
			class="mt-30 pa-24 bg-white radius-20"
			style="width: 870px; margin: 0 auto"
		>
			<div
			>
				<h3 class="text-left color-primary size-px-20">글 수정</h3>
				<hr class="under-line-bbb mt-16">

				<div class="mt-20 bg-white flex-1">
					<div
						v-if="!is_notice"
						@click="onBoard"
						class="text-left box radius-20 justify-space-between align-center size-px-14 bg-gray-light"
						style="border: 1px solid #dadada; padding:7px 20px;"
					>
						<div>{{ text_board_name }}</div>
						<button
							v-if="false"
						><v-icon>mdi-chevron-down</v-icon></button>
					</div>

					<div
						v-if="is_select_board_type"
						class=" mt-10 radius-10 pa-10 bg-gray01"
					>
						<ul
							@cancel="is_select_board_type = false"
							class="scroll-black select-board-type"
							style="max-height: 150px; overflow: auto"
						>
							<template
								v-for="(item, index) in items_board_config"
							>
								<li
									v-if="item.board_authority.reg_fg == 'Y'"
									:key="'item_' + index"
									class=" pb-10-not-last color-gray cursor-pointer pl-10"
									:class="{on: item.board_number == item_board_config.board_number }"
									@click="setBoard(item)"
								>{{ item.board_name }}</li>
							</template>
						</ul>
					</div>

					<div
						v-if="is_notice"
						class="article_write_form mt-20"
					>
						<div class="">
							<input
								v-model="item_bbs.title"
								placeholder="제목을 입력하세요."
								class="width-100 box radius-20 pa-10-20 color-333"
								style="border: 1px solid #dadada"
								maxlength="50"
							/>
						</div>
					</div>

					<div class="mt-20 pa-20 scroll-black box radius-10  "
						style="border: 1px solid #dadada">
						<!-- TODO : 내용입력시 placeholde 및 .ct_empty 클래스 제거 -->
						<textarea
							v-model="item_bbs.content"
							placeholder="내용을 입력하세요."
							ref="content"
							rows="20"
							class="size-px-14 color-333"
							style="width: 100%;"

							@input="reSize"

						></textarea>
					</div>

					<div
						v-if="is_notice"
						class="mt-20 flex-row justify-space-between align-center"
					>
						<div class=""><em class="size-px-16 font-weight-500">댓글 작성</em> <span class="color-gray">댓글 작성 여부를 선택합니다.</span></div>
						<div class="option_check-toggle">
							<p class="toggle-group">
								<!-- TODO : 수신 허용시 .on 클래스 추가 -->
								<label><span class="btn-toggle" :class="{ on: item_bbs.is_comment}"><input v-model="item_bbs.is_comment" type="checkbox" value="true" style="visibility: hidden"/><em class="hide">공지사항 알림</em></span></label>
							</p>
						</div>
					</div>

					<mafia_vote
						v-if="is_vote"
						:vote="item_bbs.vote"
						:cartel="item_cartel"
						:bbs="item_bbs"
						:user="user"

						@cancel="removeVote"
						@change="setVote"

						class="mt-20"
					></mafia_vote>

					<template
						v-if="upload_files.length > 0"
					>
						<hr class="under-line mt-20"/>
						<!-- 미디어 영역 -->
						<div class="mt-20 text-left size-px-16 font-weight-500"> 첨부파일 </div>

						<div
							class="mt-20"
						>
							<ul class="media_wrap">
								<draggable
									v-model="upload_files"
									handle=".handle"
								>
									<li
										v-for="(file, index) in upload_files"
										:key="'files_' + index"
										class="flex-row mb-10-not-last items-center"
									>
										<div
											class="photo_upload_item position-relative bg-gray"
										>
											<img
												v-if="file.post_appendix_file_div_code == 'CA01000001'"
												:src="file.src"
												class="object-cover"
											/>

											<video
												v-if="file.post_appendix_file_div_code == 'CA01000002'"
												width="100px"
												height="100px"

												:ref="'video_' + index"
											>
												<source
													:src="file.src"
													type="video/webm"
												>
											</video>

											<video
												v-if="file.post_appendix_file_div_code == 'CA01000003'"
												width="100px"
												height="100px"
												:src="file.src"
												:poster="require('@/assets/image/web/notify_cartel/ico_noti_sound.svg')"
												style="object-fit: cover"
											></video>

											<button class="position-absolute" style="top: 5px; right: 5px;">
												<v-icon
													@click="onRemove(file)"
													class="bg-white"
												>mdi-close-circle</v-icon>
											</button>
										</div>

										<div class="flex-1 pl-10">
											<p>{{  file.name }}</p>
										</div>

										<div class="cursor-pointer handle">
											<div class="drag_bar">
												<img :src="require('@/assets/image/btn_order.png')" alt="">
											</div>
										</div>
									</li>
								</draggable>
							</ul>
						</div>
				<!-- //미디어 영역 -->
				</template>

				<div
					v-if="is_subscribe"
					class="size-px-16 pt-10 pb-50"
				>
					<div
						class="pa-20 bg-white text-left"
					>
						<div>적용 플랜 선택</div>
						<div class="mt-10 size-px-13 color-gray">선택된 플랜 등급이상 구독 멤버에 한해 이글을 볼 수 있습니다</div>

						<div class="mt-10">
							<ul>
								<li
									v-for="(payment, p_index) in items_payment"
									:key="'payment_' + p_index"

									@click="setPlan(payment)"
									class="mt-10"
								>
									<v-icon
										v-if="payment.is_check"
										class="color-blue"
									>mdi-checkbox-marked-circle</v-icon>
									<v-icon
										v-else
									>mdi-checkbox-blank-circle-outline</v-icon>
									<span class="size-px-14 ml-10">{{ payment.subscrp_plan_name}}</span>
								</li>
							</ul>
						</div>
					</div>
					<hr class="top-line">
					<div
						class="pa-20 mt-10 bg-white"
					>
						<div class="justify-space-between items-center" @click="onSpecial">
							고급설정
							<v-icon>mdi-chevron-right</v-icon>
						</div>
						<div
							v-if="false"
							class="mt-20 justify-space-between" @click="onList"
						>
							글 불러오기
							<v-icon>mdi-chevron-right</v-icon>
						</div>
					</div>
				</div>

				<div
					class="flex-row justify-start ptb-20 toolbar under-line"
				>
					<template
						v-if="!is_cheer"
					>
						<button
							@click="toFile"
						><label><span class="toolbar_icon tb_photo mr-20 color-888 size-px-14">사진</span><input_file accept="image/*" multiple @change="setFile('image', $event)"/></label></button>

						<button
							@click="toFile"
						><label><span class="toolbar_icon tb_viedo mr-20 color-888 size-px-14" >비디오</span><input_file accept="video/*" multiple @change="setFile('video', $event)" ref="file_video"/></label></button>

						<button
							@click="toFile"
						><label><span class="toolbar_icon tb_sound mr-20 color-888 size-px-14" >사운드</span><input_file accept="audio/*" multiple @change="setFile('audio', $event)" ref="file_sound"/></label></button>

						<button
							@click="resetVote"
						><span class="toolbar_icon tb_vote color-888 size-px-14">투표</span></button>
					</template>
				</div>

				<div
					class="bg-white pt-20 flex-row justify-end items-center"
				>

						<div class="text-right">
							<button
								class="btn-inline btn-dark-gray radius-20 mr-10 pa-10-30"
								@click="isCancel"
							>목록</button>
							<button
								class="btn-inline btn-primary radius-20 pa-10-30"
								@click="doPost"
							>완료</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<PopupSelect
			v-if="is_select"
			@cancel="is_select = false"
		>
			<template
				v-slot:title
			>게시판 선택</template>
			<template
				v-slot:list
			>
				<template
					v-for="(item, index) in items_board_config"
				>
					<li
						v-if="item.board_type_code != 'CA00700001'"
						:key="'item_' + index"
						style="padding: 10px 0"
						@click="setBoard(item)"

						class="cursor-pointer"
					>{{ item.board_name }}</li>
				</template>
			</template>
		</PopupSelect>


		<PopupConfirm
			v-if="is_popup"
			@cancel="is_popup = false"
			@click="setVideo"
		>
			<template
				v-slot:title
			>비디오</template>
			<template
				v-slot:main-txt
			>
				유튜브 링크를 입력하세요
			</template>
			<template
				v-slot:sub-txt
			>
				<div
					v-for="i in video_size"
					:key="'i_' + i"
				>
					<input
						v-model="video[video_size - i]"
						type="text"
						style="border: 1px solid #ddd; padding: 10px"
						class="mt-10"
						placeholder="https://"
					/>
					<v-icon
						v-if="i == video_size"
						@click="setVideoSize(true, i)"
						class="ml-10 mt-5"
					>mdi-plus-circle-outline</v-icon>
					<v-icon
						v-else
						@click="setVideoSize(false, i)"
						class="ml-10 mt-5"
					>mdi-minus-circle-outline</v-icon>
				</div>
			</template>
		</PopupConfirm>

		<PopupLayer
			v-if="is_on_special"
		>
			<template
				v-slot:body
			>
				<div class="width-320  ma-auto bg-white  radius-20 overflow-hidden">
					<div class="pa-20">
						<h3 class="pop_tit pop_tit_logo text-center under-line mt-30 pb-30">{{ $language.subscribe.title_subscribe_advanced }} <!-- 고급설정 --></h3>
						<div
							class=" mt-10 bg-white"
						>
							<div>
								<div
									class="justify-space-between items-center"
									@click="$set(item_bbs, 'bdy_release_fg', item_bbs.bdy_release_fg == 'Y' ? 'N' : 'Y')"
								>
									<h4 class="size-px-16">{{ $language.subscribe.title_subscribe_open }} <!-- 본문 공개 --></h4>
									<p class="toggle-group">
										<!-- TODO : 수신 허용시 .on 클래스 추가 -->
										<span
											class="btn-toggle"
											:class="{ on: item_bbs.bdy_release_fg == 'Y' }"
										><em class="hide">{{  $language.common.dark_mode }}</em></span>
									</p>
								</div>
								<div class="mt-10 size-px-13 color-gray text-left">비구독 멤버에게 본문을 공개합니다</div>
							</div>
							<div class="mt-20">
								<div
									class="justify-space-between items-center"
									@click="$set(item_bbs, 'comment_release_fg', item_bbs.comment_release_fg == 'Y' ? 'N' : 'Y')"
								>
									<h4 class="size-px-16">{{ $language.subscribe.title_subscribe_open_comment }} <!-- 댓글 공개 --></h4>
									<p class="toggle-group">
										<!-- TODO : 수신 허용시 .on 클래스 추가 -->
										<span
											class="btn-toggle"
											:class="{ on: item_bbs.comment_release_fg == 'Y' }"
										><em class="hide">{{  $language.common.dark_mode }}</em></span>
									</p>
								</div>
								<div class="mt-10 size-px-13 color-gray  text-left">비구독 멤버에게 댓글을 공개합니다</div>
							</div>
						</div>
					</div>
					<div
						class=" justify-space-between "
					>
						<button
							class="flex-1 btn_fill_gray pa-10"
							@click="offSpecial"
						>닫기</button>
						<button
							class="flex-1 btn_fill_blue pa-10"
							@click="postSubscribe"
						>확인</button>
					</div>
				</div>
			</template>
		</PopupLayer>

		<PopupConfirm
			v-if="is_on_remove"
			@click="postRemoveFile"
			@cancel="offRemove"
		>

			<template v-slot:title>{{ $language.add.title_remove_file}} <!-- 첨부파일 삭제 --></template>
			<template
				v-slot:main-txt
			>{{ $language.add.txt_remove_file_main}} <!-- 해당 첨부파일을 삭제하시겠습니까?--></template>
			<template
				v-slot:sub-txt
			>{{ $language.add.txt_remove_file_sub}} <!-- 삭제된 파일은 복구되지 않습니다.--></template>

		</PopupConfirm>
	</div>
</template>

<style>
.m_video {	display: flex; flex-direction: column; justify-content: center; border: 1px solid #ddd; background-color: var(--bg-Gray02); border-radius: 10px; overflow: hidden}

.article_write_form_subject { font-size: 16px; display: flex; justify-content: space-between; padding: 10px}

.ct_textarea textarea { height: none; min-height: none;}

</style>

<script>

import PopupConfirm from "@/view/Layout/PopupConfirm";
import PopupSelect from "@/view/Layout/PopupSelect";
import draggable from 'vuedraggable'
import mafia_vote from '@/view/Cartel/mafiaVote'
import input_file from '@/components/InputFile'
import PopupLayer from "@/view/Layout/PopupLayer";
import {encodeStorage} from "@/resources/storage/localStorage";

export default {
	name: 'mafia1272'
	, components: {PopupLayer, PopupConfirm, PopupSelect, draggable, mafia_vote, input_file}
	, props:['user', 'cartel', 'board', 'bbs']
	, data: function(){
		return {
			program: {
				name: ''
				, title: ''
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
				, bg_contents: 'bg-gray01'
				, cartel: {}
			}
			, item_cartel: {}
			, item_board_config: {
				id: ''
			}
			, item_bbs: {
				code: this.$route.params.code
				, user_id: this.user.id
				, nick: this.user.nick
				, video:[]
				, vote: null
				, title: ''
				, content: ''
				, is_comment: true
			}
			, files: []
			, video: []
			, video_size: 1
			, items_board_config: []
			, is_select: false
			, bbs_id: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
			, is_popup: false
			, file_max: 8
			, is_vote: false
			, vote_fg: false
			, upload_files: []
			, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel.cartl_number
			, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
			, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
			, board_type: this.$route.params.code ? this.$route.params.code : this.bbs.board_type_code
			, is_select_board_type: false
			, is_on_special: false
			, is_on_list: false
			, items_list: []
			, item_list: {}
			, items_payment: []
			, is_on_remove: false
			, post_appendix_file_indexnumber: ''
		}
	}
	, computed: {
		is_disabled: function(){
			let t = false
			if(!this.item_bbs.content || this.item_bbs.content.trim() == ''){
				t = true
			}
			if(this.item_board_config.code == 'CA00700001' && (!this.item_bbs.title || this.item_bbs.title == '')){
				t = true
			}
			return t
		}
		, is_cheer: function(){
			let t = false
			if(this.item_board_config.board_type_code == 'CA00700002'){
				t = true
			}
			return t
		}
		, text_title: function(){
			let t = '글쓰기'

			if(this.bulletin_number){
				t = '글수정'
			}

			return t
		}
		, text_board_name: function(){
			let t = ''

			if(this.bulletin_number){
				t =  this.item_board_config.board_name
			}

			return t
		}
		, is_notice: function(){
			let t = false

			if(this.board_type == 'CA00700001'){
				t = true
			}

			return t
		}
		, vote_info: function(){
			if(this.vote_fg){
				return {
					vote_info: ''
					, vote_title: this.item_bbs.vote.title
					// , vote_startdt: this.item_bbs.vote.date + ' ' + this.item_bbs.vote.time.join(':')
					, vote_enddate: this.makeEnddate()
					, vote_type_code: this.item_bbs.vote.is_multiple ? 'CA01400002' : 'CA01400001'
				}
			}else{
				return {

				}
			}

		}
		, is_subscribe: function(){
			let t = false
			if(this.item_board_config.board_type_code == 'CA00700005'){
				t = true
			}
			return t
		}
		, post_subscrp_plan_list: function(){
			return this.items_payment.filter( (item) => {
				if(item.is_check){
					return {
						subscrp_plan_number: item.subscrp_plan_number
					}
				}
			})
		}
		, post_file_list: function(){
			return this.upload_files.filter( (item, index) => {
				console.log(index, item)
				item.post_appendix_file_indexnumber = index + 1
				return item
			})
		}
	}
	,methods: {
		getBoardConfig: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.cartl_number
						, board_number: this.board_number
					}
					, type: true
				})

				if(result.success){
					this.item_board_config = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postFiles: async function (){
			return true
		}
		, postFile: async function(appendix_file_div_code, file){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_file
					, data: {
						member_number: this.user.member_number
						, file_upload_code: 'CM00700003'
						, appendix_file_div_code: appendix_file_div_code
						, appendix_file_name: file
					}
					, multipart: true
					, type: true
				})

				if(result.success){
					return result.data.file_url
				}else{
					throw result.message
				}

			}catch (e) {
				this.$log.console('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postNotice: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_notice_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.cartl_number
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
							, post_title: this.item_bbs.title
							, post_contents: this.item_bbs.content
							, post_file_list: this.post_file_list
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							// , vote_fg: this.vote_fg ? 'Y' : 'N'
							// , vote_info: this.vote_info
							// , vote_list: this.item_bbs.vote.items
							, comment_writing_fg: this.item_bbs.is_comment ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						await this.getCartelNoticeInfo()

						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success })
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postCheer: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cheer_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.cartl_number
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
							, post_contents: this.item_bbs.content
						}
						, type: true
					})

					if(result.success){
						//this.$emit('to', { name: 'mafia053', params: { idx: this.cartl_number, code: this.board_type, b_id: this.board_number }})

						/*
						let item = {
							cartl_number: this.cartl_number
							, board_type: this.board_type
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
						}

						this.$emit('click', item)

						 */

						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success })
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}


		, postSubscribe: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_subscribe_board_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.cartl_number
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
							, post_contents: this.item_bbs.content
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							, post_file_list: this.post_file_list
							, bdy_release_fg: this.item_bbs.bdy_release_fg
							, comment_release_fg: this.item_bbs.comment_release_fg
							, post_subscrp_plan_list: this.post_subscrp_plan_list
						}
						, type: true
					})

					if(result.success){
						// this.$emit('to', { name: 'mafia049', params: { idx: this.cartl_number, code: this.board_type }})
						this.$bus.$emit('notify', { type: 'success', message: '정상적으로 처리되었습니다.'})
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postBbs: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_bbs_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.cartl_number
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
							, post_contents: this.item_bbs.content
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							, post_file_list: this.post_file_list
							//, vote_fg: this.vote_fg ? 'Y' : 'N'
							//, vote_info: this.vote_info
							//, vote_list: this.item_bbs.vote.items
						}
						, type: true
					})

					if(result.success){
						// this.$emit('to', { name: 'mafia049', params: { idx: this.cartl_number, code: this.board_type }})

						/*
						let item = {
							cartl_number: this.cartl_number
							, board_type: this.board_type
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
						}
						 */

						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success })

					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBoardList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_menu
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel.cartl_number
					}
					, type: true
				})

				if (result.success) {
					this.items_board_config = result.data.board_list
					if(!this.$route.params.b_id && !this.bbs.bulletin_number){
						this.$set(this, 'item_board_config', this.items_board_config[0])
					}
				} else {
					throw result.message
				}
			} catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.cartl_number
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.program.cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onBoard: function(){
			// this.is_select = true
			return false
		}
		, setBoard: function(item){
			this.is_select = false
			this.item_board_config = item
			this.item_bbs.code = item.code
			this.$log.console('this.item_board_config', this.item_board_config)
			//this.$emit('to', { name: this.$route.name, params: { idx: this.$route.params.idx, code: item.code}})
		}
		, onRemove: function(item){
			this.post_appendix_file_indexnumber = item.post_appendix_file_indexnumber
			this.is_on_remove = true
		}
		, offRemove: function(){
			this.is_on_remove = false
		}
		, postRemoveFile: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_file_delete
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, bulletin_number: this.$route.params.bbs_id
						, post_appendix_file_indexnumber: this.post_appendix_file_indexnumber
					}
					, type: true
				})

				if(result.success){

					this.files = []
					this.upload_files = []
					this.item_bbs.post_file_list = result.data.post_file_list
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							await this.getRealFile(val, key)
						}
					}

					this.is_on_remove = false
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, removeVideo: function(index){
			this.video.splice(index, 1)
		}
		, getBbs: function(){

			switch (this.board_type){
				case 'CA00700001':
					this.getCartelNoticeInfo()
					break
				case 'CA00700002':
					this.getCartelCheerInfo()
					break
				case 'CA00700005':
					this.getCartelSubscribe()
					break
				default:
					this.getCartelBbs()
					break
			}
		}
		, getCartelSubscribe: async function(){
			try{
				if(!this.bbs_id){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_board_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					this.board_type = this.item_bbs.board_type_code

					this.is_vote = result.data.vote_fg == 'Y' ? true : false
					this.vote_fg = result.data.vote_fg == 'Y' ? true : false

					this.item_bbs.content = this.item_bbs.post_contents
					this.$set(this.item_bbs, 'is_comment',  this.item_bbs.comment_writing_fg == 'Y' ? true : false)

					/*
					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'
*/

					this.files = []
					this.upload_files = []
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							await this.getRealFile(val, key)
						}
					}

					if(this.item_bbs.post_subscrp_plan_list.length > 0){
						this.setPlan(this.item_bbs.post_subscrp_plan_list[0])
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelBbs: async function(){
			try{
				if(!this.bbs_id){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					this.board_type = this.item_bbs.board_type_code

					this.is_vote = result.data.vote_fg == 'Y' ? true : false
					this.vote_fg = result.data.vote_fg == 'Y' ? true : false

					this.item_bbs.content = this.item_bbs.post_contents
					this.$set(this.item_bbs, 'is_comment',  this.item_bbs.comment_writing_fg == 'Y' ? true : false)

					/*
					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'
*/

					this.files = []
					this.upload_files = []
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							this.$log.console(key)
							this.files.push({
								name: val.post_appendix_file_org_url
								, src: val.post_appendix_file_org_url
								, type: val.post_appendix_file_div_code == 'CA01000001' ? 'image' : val.post_appendix_file_div_code == 'CA0100002'? 'audio' : 'video'
							})

							this.upload_files.push({
								post_appendix_file_div_code: val.post_appendix_file_div_code
								, post_file_url: val.post_appendix_file_org_url
								, post_appendix_file_indexnumber: val.post_appendix_file_indexnumber
								, post_appendix_file_change_before_indexnumber: val.post_appendix_file_indexnumber
								, src: val.post_appendix_file_org_url
							})
						}
					}
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelNoticeInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_notice_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					this.board_type = this.item_bbs.board_type_code

					this.is_vote = result.data.vote_fg == 'Y' ? true : false

					this.item_bbs.content = this.item_bbs.post_contents
					this.item_bbs.title = this.item_bbs.post_title
					this.$set(this.item_bbs, 'is_comment',  this.item_bbs.comment_writing_fg == 'Y' ? true : false)

					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'

					this.files = []
					this.upload_files = []
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							this.$log.console(key)
							this.files.push({
								name: val.post_file_name
								, src: val.post_file_url
								, type: val.post_appendix_file_div_code == 'CA01000001' ? 'image' : val.post_appendix_file_div_code == 'CA0100002'? 'audio' : 'video'
							})
							this.upload_files.push(val)
						}
					}

				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelCheerInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_cheer_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					this.board_type = this.item_bbs.board_type_code

					this.item_bbs.content = this.item_bbs.post_contents
					this.$set(this.item_bbs, 'is_comment',  this.item_bbs.comment_writing_fg == 'Y' ? true : false)

					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPopup: function(){
			this.is_popup = true
		}
		, setFile: function(type, e){

			let file_count = this.files.length + e.length
			if (file_count > this.file_max) {
				this.$bus.$emit('notify', {type: 'error', message: this.$language.common.error_file_limit})
				return false
			}


			let post_appendix_file_div_code = ''

			for (let file of e) {

				let file_index = this.files.length + 1

				if (type.indexOf('video') > -1) {
					post_appendix_file_div_code = 'CA01000002'
				} else if (type.indexOf('audio') > -1) {
					post_appendix_file_div_code = 'CA01000003'
				} else {
					post_appendix_file_div_code = 'CA01000001'
				}

				this.upload_files.push({
					post_appendix_file_div_code: post_appendix_file_div_code
					, post_file_url: file
					, post_appendix_file_indexnumber: file_index
					, post_appendix_file_change_before_indexnumber: file_index
					, src: file
				})
			}
		}
		, setVideo: function(e){

			for(let video of e.target.files){
				const reader = new FileReader()
				
				reader.onload = (e) => {
					this.video.unshift(e.target.result)
				}
				this.$log.console('video ', video)
				reader.readAsDataURL(video)
			}
		}
		, handleClick(event, ref) {
			this.$refs[ref][event]();
		}
		, handleSearch(e, platform) {
			if (platform === "youtube") this.youtubeLink = e.target.value;
			else this.vimeoLink = e.target.value;
		}
		, setVideoSize: function(type, index){
			if(this.video_size > 5){
				return false
			}
			if(type){
				this.video_size++
			}else{
				this.video.splice(this.video_size - index, 1)
				this.video_size--
			}
		}
		, setVote: function(e){
			this.$set(this.item_bbs, 'vote', e)
		}
		, resetVote: function(){
			this.$bus.$emit('notify', { type: 'error', message: '글수정시 투표등록, 수정 및 삭제가 불가능합니다'})
			return false
		}
		, removeVote: function(){
			this.item_bbs.vote = null
			this.is_vote = false
			this.vote_fg = false
		}

		, reSize: function(e){
			this.$log.console('reSize', e)
			/*
			e.target.style.height = 'auto'
			e.target.style.height = e.target.scrollHeight + 'px'

			 */
		}
		, controlVideo: function(index){
			this.$log.console('this.$refs video_' + index, this.$refs['video_' + index])
			this.$refs['video_' + index][0].play()
		}
		, doPost: function(){
			switch (this.board_type){
				case 'CA00700001':
					this.postNotice()
					break
				case 'CA00700002':
					this.postCheer()
					break
				case 'CA00700005':
					this.postSubscribe()
					break
				default:
					this.postBbs()
					break
			}
		}
		, makeEnddate: function(){
			return this.item_bbs.vote.date + ' ' + this.item_bbs.vote.time.join(':')
		}
		, goBack: function(){

			if(this.cartel){

				let item = {
					cartl_number: this.cartl_number
					, board_type: this.board_type
					, board_number: this.board_number
					, bulletin_number: this.bulletin_number
				}

				this.$emit('click', item)
			}else{
				this.$router.back()
			}
		}
		, toFile: function(){
			this.$bus.$emit('not_pause')
		}
		, isCancel: function(){
			if(confirm('저장되지 않은 내용은 반영되지 않습니다.')){
				this.$router.back()
			}
		}
		, getPlan: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_subscribe_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.items_payment = result.data.subscrp_plan_list
					// this.items = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setPlan: function(item){
			this.items_payment.filter( (payment) => {
				if(item.subscrp_plan_number == payment.subscrp_plan_number){
					this.$set(payment, 'is_check', true)
				}else{
					this.$set(payment, 'is_check', false)
				}
			})
		}
		, onSpecial: function(){
			this.is_on_special = true
		}
		, offSpecial: function(){
			this.is_on_special = false
		}
		, onList: function(){
			this.is_on_list = true
		}
		, offList: function (){
			this.is_on_list = false
		}
		, setList: function(item){
			this.item_list = item
			this.offList()
		}

		, getRealFile: async function(item, index){

			let domain = process.env.VUE_APP_DOMAIN
			let domain_m = process.env.VUE_APP_DOMAIN_M
			let domain_stg = process.env.VUE_APP_DOMAIN_STG
			let domain_stgm = process.env.VUE_APP_DOMAIN_STG_M
			let domain_dev = process.env.VUE_APP_DOMAIN_DEV
			let domain_devm = process.env.VUE_APP_DOMAIN_DEV_M
			let dev = process.env.VUE_APP_DEV
			let server = process.env.VUE_APP_SERVER
			let stg = process.env.VUE_APP_STG
			let local = process.env.VUE_APP_LOCAL
			let location = window.location.href

			let baseUrl = ''

			// 운영계 API 호출
			if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1) {
				baseUrl = server

				// 검증계 API 호출
			}else if(location.indexOf(domain_stgm) > -1 || location.indexOf(domain_stg) > -1){
				baseUrl = stg

				// 개발계 API 호출
			}else if(location.indexOf(domain_dev) > -1 || location.indexOf(domain_devm) > -1){
				baseUrl = dev

				// 로컬 API 호출
			}else{
				baseUrl = local
			}

			let url = baseUrl + '/' + this.$api_url.api_path.get_board_file_stream
			let default_header = {
				'Content-Type': 'application/json'
				, 'v': '1.0.0'
				, 'Content-Security-Policy' : 'upgrade-insecure-requests'
			}

			let d = url.split('/::')
			if(d.length > 1){
				default_header.v = d[1]
				url = d[0]
			}

			let session_token = encodeStorage.getSessionToken()
			//console.log('session_token', session_token)

			if(session_token){
				default_header.a = session_token
				//console.log('default_header', default_header)
			}

			let data = {
				member_number: this.user.member_number
				, cartl_number: this.$route.params.idx ? this.$route.params.idx : item.cartl_number
				, board_number: this.$route.params.b_id ? this.$route.params.b_id : item.board_number
				, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : item.bulletin_number
				, appendix_file_div_code: item.post_appendix_file_div_code
				, appendix_file_url: item.post_appendix_file_org_url
				, img_pay_div_code: item.post_appendix_file_img_pay_div_code
			}

			data = new URLSearchParams(data).toString()

			url += '?' + data

			await fetch(url, {
				headers: default_header
			})
				.then((response) => response.body)
				.then((body) => {
					this.$bus.$emit('on', true)
					const reader = body.getReader();

					return new ReadableStream({
						start(controller) {
							return pump();

							function pump() {
								return reader.read().then(({ done, value }) => {
									// When no more data needs to be consumed, close the stream
									if (done) {
										controller.close();
										return;
									}

									// Enqueue the next data chunk into our target stream
									controller.enqueue(value);
									return pump();
								});
							}
						},
					});
				})
				.then((stream) => new Response(stream))
				.then((response) => response.blob())
				.then((blob) => {

					let reader = new FileReader()
					reader.onload = (e) => {
						if(e.target.result){
							this.upload_files.push({
								post_appendix_file_div_code: item.post_appendix_file_div_code
								, post_file_url: item.post_appendix_file_org_url
								, post_appendix_file_indexnumber: index + 1
								, post_appendix_file_change_before_indexnumber: index + 1
								, src: e.target.result
							})
						}
					}

					reader.readAsDataURL(blob)
				})
				.catch(() => {
					this.$set(this.items, index, {
						file_url: 'error'
					})
				}).finally( () => {
					this.$bus.$emit('on', false)
				});

		}
	}
	,async created() {
		this.$bus.$emit('onLoad', this.program)
		/*
		await this.getCartel()
		await this.getBoardList()
		await this.getBoardConfig()
		*/

		this.$log.console('mafia127-2', this.bbs)

		if(this.cartel){
			this.item_cartel = this.cartel
		}else{
			await this.getCartel()
		}

		await this.getBoardList()
		await this.getBoardConfig()
		await this.getPlan()
		await this.getBbs()

	}


}
</script>

<style>
.select-board-type .on {
	color: #333 !important;
	text-shadow: 0.3px 0 #333;
}

.photo_upload_item {
	width: 100px;
	height: 100px;
}

input::placeholder{
		font-size: 14px;
	}
	textarea::placeholder{
		font-size: 14px; color: #bbb;
	}
</style>