<template>
	<div
		ref="cartelList"
	>
		<div
			class="bg-white radius-20 pa-10 "
			:class="{ 'position-fixed fixed-cartel-list': is_top_list }"
		>
			<div class="flex-row align-center gap-10 pa-10">
				<img src="@/assets/image/web/home_cartel/ico_cartel.svg" alt="카르텔 아이콘">
				<h3 class="size-px-14">가입 카르텔</h3>
			</div>

			<div
				class="box-list  scroll-black overflow-y-auto pr-10 "
				style="max-height: 400px"
			>
				<ul
					ref="box-recomm"
					class=""
				>
					<li
						v-for="(item, index) in items"
						:key="'item_recomm_' + index"
						class="hover-scale radius-20 pa-10"
						:class="{ on: item.cartl_number == $route.params.idx }"
						@click="toCartel(item)"
					>
						<div class="box-img ">
							<div class="full-height ">
								<img
									:src="item.cartl_img_url"
									@error="$bus.$emit('onErrorCartelProfileImage', $event)"
								/>
							</div>
							<!-- <div class="gradient-title">
								<span class="title">{{ item.cartl_name}}</span>
							</div> -->
						</div>
						<div class="flex-column w-100 items-start overflow-hidden">
							<Marquee
								class="title size-px-14 font-weight-500 color-333"
							>
								<template
									v-slot:text
								>{{  item.cartl_name }}</template>
								<template
									v-slot:text-sub
								>{{  item.cartl_name }}</template>
							</Marquee>
							<div
								v-if="item.count"
								class="discription mt-5"
							>
								<span>새글</span>
								<span
									class="color-888"
								> <!-- 새글 개수 데이터 영역 --> {{  item.count | makeComma }} </span>
							</div>
						</div>
			
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import Marquee from "@/components/Marquee";
	export default {
		name: 'CartelList'
		, components: {Marquee}
		, props: ['user', 'body']
		, data: function(){
			return {
				items: []
				, item_search: {
					page_number: 1
					, pagerecnum: 10
				}
				, is_top_list: false
			}
		}
		, computed: {

		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					this.is_add = false
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_main_info
						, data: {
							member_number: this.user.member_number
							, cartl_list_page: {
								page_number: this.item_search.page_number
								, pagerecnum: this.item_search.pagerecnum
							}
							, nrt_post_list_page: {
								page_number: this.item_search.page_number
								, pagerecnum: this.item_search.pagerecnum
							}
						}
						, type: true
					})

					if(result.success){
						this.items = result.data.member_cartl_list
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_add = true
				}
			}
			, toCartel: function(item){
				this.$bus.$emit('to', { name: 'mafia049', path: '/cartel/mafia049/' + item.cartl_number, params: { idx: item.cartl_number }})
			}
		}
		, created() {
			this.getData()

			window.addEventListener('scroll', () => {

				let scrollTop = window.scrollY
				// this.$log.console('let scrollTop', scrollTop)
				if(scrollTop >= 381){
					this.is_top_list = true
				}else{
					this.is_top_list = false
				}

			})
		}
	}
</script>

<style>
	.fixed-cartel-list { left: calc((100% - 1200px) / 2); top: 110px; width: 270px}

</style>