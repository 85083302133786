<template>
	<div
		:class="wrap" id="wrap"
	>
		<Header
			:user="user"
			@to="to"
		></Header>
		<CartelTop
			:user="user"
			:cartel="cartel"
			class="wrap-banner-cartel bg-white"
		></CartelTop>

		<div
			class="wrap-contents"
			:class="program.bg_contents"
		>
			<div
				class="contents position-relative"
			>
				<div
					class="flex-row"
				>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import CartelTop from "@/view/Cartel/CartelTop";
import Header from "@/view/Layout/Header";

export default {
	name: 'cartel_bbs_detail'
	, props: ['user', 'cartel', 'board', 'bbs', 'is_item_detail_comment']
	, components: { Header, CartelTop }
	, data: function(){
		return {
			program: {
				name: '게시물 상세'
				, title: '게시물 상세'
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'
				, from: this.$route.params.from
				, bg_contents: 'bg-gray01'
			}
			, item_cartel: {}
			, item_board_config: {}
			, item_bbs: {
				post_registdate: ''
				, vote_fg: 'N'
			}
			, items_comment: []
			, rows: 1
			, item_comment: {
				cartel_id: this.$route.params.idx
				, bbs_code: this.$route.params.code
				, bbs_id: this.$route.params.bbs_id
				, user_id: this.user.id
				, user_nick: this.user.nick
				, user_img_src: this.user.img_src
				, upper_id: ''
				, upper_nick: ''
				, content: ''
			}
			, is_emoji: false
			, is_more: false
			, is_098: false
			, item_search: this.$storage.init({
				page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			})
			, is_comment_view: this.is_item_detail_comment
			, item_update: {
				sympaty_icon_code: ''
			}
			, items_emoji: []
			, item_profile: {}
		}
	}
	, computed: {
		text_title: function(){

			let t = this.item_bbs.nick

			if(this.$route.params.code == 'CA00700001'){
				t = this.item_bbs.title
			}

			return t
		}
		, is_notice: function(){
			let t = false
			if(this.$route.params.code == 'CA00700001'){
				t = true
			}
			return t
		}
		, video: function(){
			let t = []
			if(this.item_bbs.video){
				if(this.item_bbs.video.length > 0){
					t = this.item_bbs.video
				}
			}
			return t

		}
		, files: function(){
			let t = []
			if(this.item_bbs.post_file_list){
				if(this.item_bbs.post_file_list.length > 0){
					t = this.item_bbs.post_file_list
				}
			}
			return t

		}
		, sound: function(){
			let t = []
			if(this.item_bbs.sound){
				if(this.item_bbs.sound.length > 0){
					t = this.item_bbs.sound
				}
			}
			return t

		}
		, is_friend: function(){
			let t = false

			return t
		}
		, list_comment: function(){
			return this.items_comment.filter(function(item){
				if(item.blok_member_fg == 'Y'){
					item.is_block = true
				}
				return item
			})
		}
	}
	, methods: {

		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getBoardConfig: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.bbs.board_number
					}
					, type: true
				})

				if(result.success){
					this.item_board_config = result.data
					await this.getBbs()
					// await this.getBbsComment()
				}else{
					if(result.code == 'E001201021'){
						this.$bus.$emit('notify', { type: 'error', message: result.message})
						this.$router.back()
					}
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getBbs: async function(){
			try{
				this.$bus.$emit('on', true)

				let url = this.$api_url.api_path.get_cartel_bbs_info
				if(this.$route.params.code == 'CA00700001'){
					url = this.$api_url.api_path.get_cartel_notice_info
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.bbs.board_number
						, bulletin_number: this.bbs.bulletin_number
					}
					, type: true
				})

				if(result.success){
					this.item_bbs = result.data
					this.items_comment = result.data.comment_list
					this.items_emoji = result.data.sympaty_list
					this.$set(this.item_bbs, 'board_number', this.bbs.board_number)
					this.item_bbs.vote = result.data.vote_info
					this.item_bbs.vote.vote_participation_people_count = result.data.vote_participation_people_count
					this.item_bbs.vote.time = result.data.vote_info.vote_end_daytime
					this.item_bbs.vote.items = result.data.vote_list
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBbsComment: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs_comment_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
						, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_comment = result.data.comment_list
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postComment: async function(){
			try{
				if(!this.item_comment.content || this.item_comment.content == ''){
					throw '댓글을 입력하세요'
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_comment
					, data: new Object(this.item_comment)
					, name: 'postComment'
				})

				if(result.success){
					if(this.item_comment.upper_id){
						for(let c of this.items_comment){
							if(c.id == this.item_comment.upper_id){
								this.$log.console('c', c)
								if(!c.reply){
									c.reply = []
								}
								c.reply.push(result.data)
							}
						}
					}else{
						this.items_comment.unshift(result.data)
					}
					this.item_comment = {
						cartel_id: this.$route.params.idx
						, bbs_code: this.$route.params.code
						, bbs_id: this.$route.params.bbs_id
						, user_id: this.user.id
						, user_nick: this.user.nick
						, user_img_src: this.user.img_src
						, upper_id: ''
						, upper_nick: ''
						, content: ''
					}
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, clearReply: function(){
			this.item_comment.upper_id = ''
			this.item_comment.upper_nick = ''
		}
		, setReply: function(comment){
			this.item_comment.upper_id = comment.upper_id
			this.item_comment.upper_nick = comment.user_nick
			//this.item_comment.content = '@' + comment.user_nick + ' '
		}
		, getTimeStory: function(type, created_at){
			this.$log.console(type, created_at)
			return this.$date.getTimeStory(created_at)
		}
		, setFile: function(e){
			this.$log.console(e)

			const reader = new FileReader()
			
			reader.onload = (e) => {
				this.$set(this.item_comment, 'img_src', e.target.result)
			}
			reader.readAsDataURL(e.target.files[0])
		}
		, toComment: function(){
			this.is_comment_view = true
			//this.$bus.$emit('to', { name: 'mafiaCommentList', params: { idx: $route.params.idx, code: $route.params.code, bbs_id: $route.params.bbs_id}})
		}
		, toReply: function(){
			this.is_comment_view = true
			//this.$bus.$emit('to', { name: 'mafiaCommentList', params: { idx: $route.params.idx, code: $route.params.code, bbs_id: $route.params.bbs_id}})
		}
		, move: async function(move_info){
			this.bbs.board_number = move_info.moving_board_number
			this.bbs.bulletin_number = move_info.bulletin_number

			this.is_more = false
			this.$bus.$emit('to', { name: 'mafia053', params: { idx: this.$route.params.idx, code: move_info.moving_board_type_code, b_id: move_info.moving_board_number}})
		}
		, mount: async function(){

			this.item_cartel = this.cartel
			this.$log.console('this.item_cartel', this.item_cartel)
			this.$log.console('this.item_board_config', this.item_board_config)
			this.$log.console('this.bbs', this.bbs)

			await this.getBoardConfig()

			this.is_more = false
		}

		, deleteBbs: async function(){
			this.$emit('deleteBbs')
		}
		, goBack: function(){
			this.$emit('cancel', this.item_update)
		}
		, setEmoji: function(code){
			this.item_update.sympaty_icon_code = code
		}
		, toModify: function(){
			this.$emit('toModify')
		}
		, on098: function(item){
			this.is_098 = true
			this.item_profile = item
			if(item.comment_member_number){
				this.item_profile.member_number = item.comment_member_number
			}
		}
	}
	,async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.mount()
	}
	, watch: {
		'item_comment.content':{
			deep: true
			, handler: function(call){
				this.$log.console('call', call)
				/*
				if(this.item_comment.upper_id){

					if(this.item_comment.upper_nick != call.slice(1, this.item_comment.upper_nick.length + 1)){
						this.item_comment.upper_id = ''
						this.item_comment.upper_nick = ''
					}
				}else{
					this.item_comment.upper_nick = ''
				}
				*/
			}
		}
	}
}
</script>

<style>
.hashtag { font-weight: normal !important;}
.VueCarousel-dot-container { margin-top: 0px !important;}
.VueCarousel-dot-container button { margin-top: 0px !important; }

.bright_radio input[type="checkbox"] { overflow: hidden; position: absolute; margin: 0; padding: 0; clip: rect(0, 0, 0, 0); width: 1px; height: 1px; border: 0; }
.bright_radio input[type="checkbox"] + label { padding-left: 34px; line-height: 24px; font-size: 14px; }
.bright_radio input[type="checkbox"] + label:before { content: ''; position: absolute; top: 0; left: 0; width: 24px; height: 24px; border: 1px solid var(--light-Gray02); box-sizing: border-box;}
.bright_radio input[type="checkbox"]:checked  + label:before { border: 1px solid var(--blue02);}
.bright_radio input[type="checkbox"]:checked  + label:after { content: ''; position: absolute; top: 6px; left: 6px; width: 12px; height: 12px; background: var(--blue02); }
.bright_radio input[type="checkbox"]:disabled + label:before { border: 1px solid var(--light-Gray02); }
.bright_radio input[type="checkbox"]:disabled + label:after { content: ''; position: absolute; top: 6px; left: 6px; width: 12px; height: 12px; background: var(--light-Gray02); border-radius: 12px; }
</style>